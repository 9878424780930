
import { defineComponent } from 'vue';
import { useNodeCanvasStore } from '../store';
import SearchableDropDown from '@/modules/globalModule/components/SearchableDropDown.vue';
import { Topic } from './storeClasses/topic';
import { Message } from './storeClasses/message';

export default defineComponent({
    name: "EditTopicBox",
    components: {
        SearchableDropDown,
    },
    props: {
        active: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            languagePython: true,
            ncStore: useNodeCanvasStore(),
            topicToEdit: null as unknown as Topic,
            name: ""
        }
    },
    methods: {
        open() {
            const topic = this.ncStore.getTopic(this.ncStore.editingTopicId);
            if (topic === undefined) {
                this.exit();
                return;
            }
            this.topicToEdit = topic;
            this.name = this.topicToEdit.name
        },
        exit() {
            this.ncStore.editingTopicId = -1
        },
        saveAndExit() {
            this.topicToEdit.name = this.name
            this.exit()
        },
        chooseTopicMsgType(msgType: Message) {
            if (msgType) this.topicToEdit.msgType = msgType.id
        }
    },
    watch: {
        active() {
            if (this.active) this.open()
        }
    }
});

