import { rmSpaces, toCamelCase, toSnakeCase } from "@/modules/globalModule/components/helperMethods";
import { useNodeCanvasStore } from "../../store";
import { nodeGenStrategy } from "./nodeGenStrategy";
import { Node } from "../storeClasses/node";
import JSZip from "jszip";
import { Topic } from "../storeClasses/topic";
import { Message } from "../storeClasses/message";
import { Service } from "../storeClasses/service";
/**
 * Code generating strategy for turning nodes into c++ code
 */
export class cppNodeGenStrategy implements nodeGenStrategy { // TODO: Implements nodeGenStrategy interface, perhaps make neater in ts migration
    public node: Node;

    /**
     * @param {Node} node - node that uses this generator strategy 
     */
    constructor(node: Node) {
        this.node = node;
    }

    /**
     * TODO: put msgType logic in parent interface when ts migration
     * For use in CMakeLists.txt
     */
    getDependencies() {
        const ncStore = useNodeCanvasStore();
        const dependencies = ["rclcpp"];
        this.node.subscribers.forEach(subscriberId => {
            const msgPkg = ncStore.getMsg(ncStore.getTopic(subscriberId).msgType).rosPackage;
            if (!dependencies.includes(msgPkg)) dependencies.push(msgPkg);
        })
        this.node.publishers.forEach(publisherId => {
            const msgPkg = ncStore.getMsg(ncStore.getTopic(publisherId).msgType).rosPackage;
            if (!dependencies.includes(msgPkg)) dependencies.push(msgPkg);
        })
        return dependencies;
    }

    generateCode(zip: JSZip) {
        this.generateHFile(zip);
        this.generateCppFile(zip);
    }

    ////////////////////////////////////////////////////////////////////////////
    //                            H file generation                           //
    ////////////////////////////////////////////////////////////////////////////

    generateHFile(zip: JSZip) {
        const ncStore = useNodeCanvasStore();
        const includeFolder: JSZip | null = zip.folder('include')
        const hFileText: string =`
#include "rclcpp/rclcpp.hpp"
${this.getMsgImports()}${this.getSrvImports()}

namespace ${toSnakeCase(ncStore.workspaceName)} {
class ${toCamelCase(this.node.name)} : public rclcpp::Node
{
    public:
        ${toCamelCase(this.node.name)}();
    private:
${this.getCallbackDefinitions() 
    + this.getPublisherDefinitions() 
    + this.getSubscriberDefinitions() 
    + this.getServerDefinitions()
    + this.getClientDefinitions()
    + this.getSrvCallbackDefinitions()
}
};
}
`;
        if (includeFolder) {
            const includeWs: JSZip | null = includeFolder.folder(toSnakeCase(ncStore.workspaceName));
            if (includeWs) includeWs.file(`${toCamelCase(this.node.name)}.h`, hFileText);
        } 
    }

    getMsgImports(): string {
        const ncStore = useNodeCanvasStore();
        const msgIdsUsed: Array<number> = [];
        this.node.subscribers.forEach((subscriberId: number) => {
            const msgId = ncStore.getTopic(subscriberId).msgType;
            if (!msgIdsUsed.includes(msgId)) msgIdsUsed.push(msgId);
        });
        this.node.publishers.forEach((publisherId: number) => {
            const msgId = ncStore.getTopic(publisherId).msgType;
            if (!msgIdsUsed.includes(msgId)) msgIdsUsed.push(msgId);
        });
        return msgIdsUsed.reduce((accumulator: string, msgId: number) => {
            let msg = ncStore.getMsg(msgId);
            return accumulator + `#include "${msg.rosPackage}/msg/${msg.name.toLowerCase()}.hpp"\n`;
        }, '');
    }

    getSrvImports(): string {
        const ncStore = useNodeCanvasStore();
        return ncStore.services.filter((srv: Service) => {
            return srv.server === this.node.id || srv.clients.includes(this.node.id);
        }).reduce((accumulator: string, srv: Service) => {
            return accumulator + `#include "${toSnakeCase(ncStore.workspaceName)}/srv/${toSnakeCase(srv.name)}.hpp"\n`;
        }, '');
    }

    getCallbackDefinitions(): string {
        const ncStore: any = useNodeCanvasStore();
        return this.node.subscribers.reduce((accumulator: string, subscriberId: number) => {
            const topic: Topic = ncStore.getTopic(subscriberId);
            const msg: Message = ncStore.getMsg(topic.msgType);
            return accumulator + 
            `        void ${toSnakeCase(topic.name)}_callback(const ${msg.rosPackage}::msg::${msg.name} &msg);\n`;
        }, '');
    }

    getSrvCallbackDefinitions(): string {
        const ncStore = useNodeCanvasStore();
        return ncStore.services.filter((srv: Service) => {
            return srv.server === this.node.id;
        }).reduce((accumulator: string, srv: Service) => {
            return accumulator + 
            `        void ${toSnakeCase(srv.name)}_srv_callback(const std::shared_ptr<${toSnakeCase(ncStore.workspaceName)}::srv::${toCamelCase(srv.name)}::Request> request,
            std::shared_ptr<${toSnakeCase(ncStore.workspaceName)}::srv::${toCamelCase(srv.name)}::Response> response);\n`
        }, '');
    }

    getServerDefinitions(): string {
        const ncStore = useNodeCanvasStore();
        return ncStore.services.filter((srv: Service) => {
            return srv.server === this.node.id;
        }).reduce((accumulator: string, srv: Service) => {
            return accumulator + 
            `        rclcpp::Service<${toSnakeCase(ncStore.workspaceName)}::srv::${toCamelCase(srv.name)}>::SharedPtr ${toSnakeCase(srv.name)}_server;\n`
        }, '');
    }
    getClientDefinitions(): string {
        const ncStore = useNodeCanvasStore();
        return ncStore.services.filter((srv: Service) => {
            return srv.clients.includes(this.node.id);
        }).reduce((accumulator: string, srv: Service) => {
            return accumulator + 
            `        rclcpp::Client<${toSnakeCase(ncStore.workspaceName)}::srv::${toCamelCase(srv.name)}>::SharedPtr ${toSnakeCase(srv.name)}_client;\n`
        }, '');
    }

    getPublisherDefinitions(): string {
        const ncStore = useNodeCanvasStore();
        return this.node.publishers.reduce((accumulator: string, publisherId: number) => {
            const publisher: Topic = ncStore.getTopic(publisherId);
            const msg: Message = ncStore.getMsg(publisher.msgType);
            return accumulator + 
            `        rclcpp::Publisher<${msg.rosPackage}::msg::${msg.name}>::SharedPtr ${toSnakeCase(publisher.name)}_pub;\n`;
        }, '');
    }
    getSubscriberDefinitions(): string {
        const ncStore = useNodeCanvasStore();
        return this.node.subscribers.reduce((accumulator: string, subscriberId: number) => {
            const subscriber: Topic = ncStore.getTopic(subscriberId);
            const msg: Message = ncStore.getMsg(subscriber.msgType);
            return accumulator + 
            `        rclcpp::Subscription<${msg.rosPackage}::msg::${msg.name}>::SharedPtr ${toSnakeCase(subscriber.name)}_sub;\n`;
        }, '');
    }

    ////////////////////////////////////////////////////////////////////////////
    //                           cpp file generation                          //
    ////////////////////////////////////////////////////////////////////////////

    generateCppFile(zip: JSZip) {
        const ncStore = useNodeCanvasStore();
        const cppFileText = `
#include "${toSnakeCase(ncStore.workspaceName)}/${toCamelCase(this.node.name)}.h"

namespace ${toSnakeCase(ncStore.workspaceName)} {

${toCamelCase(this.node.name)}::${toCamelCase(this.node.name)}() : Node("${toSnakeCase(this.node.name)}") {
${this.getSubInitialisations() 
    + this.getPubInitialisations()
    + this.getServerInitialisations()
    + this.getClientInitialisations()
}
}

${this.getCallbackImplementations()}${this.getSrvCallbackImplementations()}
}
int main(int argc, char * argv[])
{
    rclcpp::init(argc, argv);
    rclcpp::spin(std::make_shared<${toSnakeCase(ncStore.workspaceName)}::${toCamelCase(this.node.name)}>());
    rclcpp::shutdown();
    return 0;
}
`;
        const srcFolder: JSZip | null = zip.folder('src');
        if (srcFolder) srcFolder.file(`${toCamelCase(this.node.name)}.cpp`, cppFileText);
    }

    /**
     * For use in the node constructor
     */
    getSubInitialisations(): string {
        const ncStore = useNodeCanvasStore();
        return this.node.subscribers.reduce((accumulator: string, subscriberId: number) => {
            const topic: Topic = ncStore.getTopic(subscriberId);
            const msg: Message = ncStore.getMsg(topic.msgType);
            return accumulator + 
            `    ${toSnakeCase(topic.name)}_sub = this->create_subscription<${msg.rosPackage}::msg::${msg.name}>
    ("${topic.getNameNoFirstSlash()}", 10, std::bind(&${toCamelCase(this.node.name)}::${toSnakeCase(topic.name)}_callback, this, std::placeholders::_1));\n`;
        }, '');
    }

    getPubInitialisations(): string {
        const ncStore = useNodeCanvasStore();
        return this.node.publishers.reduce((accumulator: string, publisherId: number) => {
            const topic: Topic = ncStore.getTopic(publisherId);
            const msg: Message = ncStore.getMsg(topic.msgType);
            return accumulator + 
            `    ${toSnakeCase(topic.name)}_pub = this->create_publisher<${msg.rosPackage}::msg::${msg.name}>("${topic.getNameNoFirstSlash()}", 10);\n`;
        }, '');
    }

    getServerInitialisations(): string {
        const ncStore = useNodeCanvasStore();
        return ncStore.services.filter((srv: Service) => {
            return srv.server === this.node.id;
        }).reduce((accumulator: string, srv: Service) => {
            return accumulator + `    ${toSnakeCase(srv.name)}_server = this->create_service<${toSnakeCase(ncStore.workspaceName)}::srv::${toCamelCase(srv.name)}>(
        "${toSnakeCase(srv.name)}", 
        std::bind(&${toCamelCase(this.node.name)}::${toSnakeCase(srv.name)}_srv_callback, this, std::placeholders::_1, std::placeholders::_2)
    );`
        }, '');
    }

    getClientInitialisations(): string {
        const ncStore = useNodeCanvasStore();
        return ncStore.services.filter((srv: Service) => {
            return srv.clients.includes(this.node.id);
        }).reduce((accumulator: string, srv: Service) => {
            return accumulator + `    ${toSnakeCase(srv.name)}_client = this->create_client<${toSnakeCase(ncStore.workspaceName)}::srv::${toCamelCase(srv.name)}>("${toSnakeCase(srv.name)}");`
        }, '')
    }


    getCallbackImplementations(): string {
        const ncStore = useNodeCanvasStore();
        return this.node.subscribers.reduce((accumulator: string, subscriberId: number) => {
            const topic: Topic = ncStore.getTopic(subscriberId);
            const msg: Message = ncStore.getMsg(topic.msgType);
            return accumulator +
            `    void ${toCamelCase(this.node.name)}::${toSnakeCase(topic.name)}_callback(const ${msg.rosPackage}::msg::${msg.name} &msg) {}\n`
        }, '');
    }


    getSrvCallbackImplementations(): string {
        const ncStore = useNodeCanvasStore();
        return ncStore.services.filter((srv: Service) => {
            return srv.server === this.node.id;
        }).reduce((accumulator: string, srv: Service) => {
            return accumulator + 
            `void ${toCamelCase(this.node.name)}::${toSnakeCase(srv.name)}_srv_callback(
    const std::shared_ptr<${toSnakeCase(ncStore.workspaceName)}::srv::${toCamelCase(srv.name)}::Request> request,
    std::shared_ptr<${toSnakeCase(ncStore.workspaceName)}::srv::${toCamelCase(srv.name)}::Response> response
) {}\n`
        }, '');
    }
}
