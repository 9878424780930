
import { defineComponent } from 'vue';
import { useNodeCanvasStore } from '../store';
import Action from './storeClasses/action';

  export default defineComponent({
    name: "EditActionName",
    props: {
      x: {
        type: Number,
        required: true,
      },
      y: {
        type: Number,
        required: true,
      },
      action: {
        type: Action,
        required: true,
      },
    },
    data() {
      return {
        left: Number,
        elem: undefined as unknown as HTMLDivElement,
        ncStore: useNodeCanvasStore(),
      }
    },
    mounted() {
      this.elem = this.$refs.inputContainer as HTMLDivElement;
    },
    methods: {
      getLeft() {
        return (this.action.width - this.elem?.offsetWidth) / 2 + this.x;
      }
    }
  });
