/**
 * removes the element from the array only if the element occurs in the array
 * @param {*} array 
 * @param {*} element 
 */
export function removeElement<T>(array: Array<T>, element: T): void {
    let index: number = array.indexOf(element)
    if (index > -1) {
        array.splice(index, 1)
    }
}

/**
 * @param {*} string 
 * @returns The string but with all spaces replaced with _
 */
export function rmSpaces(string: string): string {
    return string.replace(' ', '_');
}

/**
 * @param {*} string - string in any format, snake case, camel case, or with spaces
 * @returns - string converted to snake case, with no special characters
 */
export function toSnakeCase(str: string): string {
    str = str.replace(/[^a-zA-Z0-9_ ]/g, ""); // remove special characters
    str = str.replace(/\s+/g, '_');
    str = str.replace(/([a-z])([A-Z])/g, '$1_$2');
    str = str.replace(/_([0-9])/g, '$1');
    str = str.toLowerCase();
    return str;
}

/**
 * @param {*} string - string in any format, snake case, camel case, or with spaces
 * @returns - string converted to camel case, with no special characters
 */
export function toCamelCase(str: string): string {
    str = str.replace(/[^a-zA-Z0-9_ ]/g, ""); // remove special characters
    str = str.replace('_', ' ');
    return str.replace(/ (\w)/g, function(match, letter: string) {
        return letter.toUpperCase();
    });
}