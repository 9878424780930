
import { defineComponent } from 'vue'


export default defineComponent({
    name: "SignInPage",
    data() {
        return {
            username: '' as String,
            email: '' as String,
            password: '' as String,
        }
    },
    methods: {
        signIn(username: String, password: String) {
            if (true) {
                console.log("logging in with username: "+username+", password: "+password)
            }
            this.$router.push('/projectManager')
        },
        resetPassword() {
            console.log("resetting password")
        },
        signUp() {
            console.log("Signing up")
        }
    }
});
