import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "input-container",
    ref: "inputContainer",
    style: _normalizeStyle({'top': `${_ctx.y}px`, 'left': `${_ctx.getLeft()}px`})
  }, [
    _withDirectives(_createElementVNode("input", {
      class: "input-style",
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.action.name) = $event)),
      placeholder: "Enter Action Name",
      onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('actionNameChanged')))
    }, null, 544), [
      [_vModelText, _ctx.action.name]
    ])
  ], 4))
}