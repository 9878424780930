
import { PropType, defineComponent } from 'vue';
import { useNodeCanvasStore } from '../store';
import { Node } from './storeClasses/node';
import { Service } from './storeClasses/service';
import { Topic } from './storeClasses/topic';
import Action from './storeClasses/action';

export default defineComponent({
  name: "CanvasSearch",
  data() {
    return {
      chosen: "",
    }
  },
  props: {
    isActive: Boolean,
    dataListElems: {
      type: Array as PropType<Array<Node | Topic | Service | Action>>,
      required: true
    },
  },
  methods: {
    onSubmit() {
      const obj = this.dataListElems.find(ea => ea.id.toString() === this.chosen);
      if (obj === undefined) {
        alert("Please enter the id of the canvas element");
      } else {
        const ncStore = useNodeCanvasStore();
        if (obj instanceof Node) {
          ncStore.editingNodeId = obj.id;
        } else if (obj instanceof Topic) {
          ncStore.editingTopicId = obj.id;
        } else if (obj instanceof Service) {
          ncStore.editingService = obj;
        }
        this.onExit();
      }
    },
    onExit(e?: Event | undefined) {
      if (e !== undefined) {
        // Try see if e.target = this.refs.outerModal works.
        if (e.target !== this.$refs.outerModal) {
          return;
        }
        // if (e.target?.id !== this.$refs?.outerModal?.id) {
        //   return;
        // }
      }
      this.$emit("onExit");
    },
    findParentName(parentId: number) {
      const ncStore = useNodeCanvasStore();
      return ncStore.actions.find(ea => ea.id === parentId)?.name;
    }
  }
});
