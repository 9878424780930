import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-76406928"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "project-square" }
const _hoisted_2 = { class: "project-square-button" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("span", _hoisted_1, [
    _createElementVNode("button", _hoisted_2, _toDisplayString(_ctx.shortName), 1),
    _createElementVNode("p", null, _toDisplayString(_ctx.projectName), 1)
  ]))
}