// adapted from here https://vuejsexamples.com/a-vue-component-for-a-simple-searchable-dropdown/
<template>
    <div class="dropdown" v-if="options">
        <!-- Dropdown Input -->
        <input class="dropdown-input"
            :name="name"
            @focus="showOptions()"
            @blur="exit()"
            @keyup="keyMonitor"
            v-model="this.searchFilter"
            :disabled="disabled"
            :placeholder="placeholder" 
        />
        <!-- Dropdown Menu -->
        <div class="dropdown-content" v-show="optionsShown">
            <div
                class="dropdown-item"
                @mousedown="selectOption(option)"
                v-for="(option, index) in filteredOptions"
                :key="index">
                {{ option?.name }}
            </div>
        </div>
    </div>
</template>

<script>
import { Message } from '@/modules/nodesCanvasModule/components/storeClasses/message';
import { faL } from '@fortawesome/free-solid-svg-icons';

export default {
    name: 'Dropdown',
    template: 'Dropdown',
    props: {
        name: {
            type: String,
            required: false,
            default: 'dropdown',
            note: 'Input name'
        },
        options: {
            type: Array,
            required: true,
            default: [],
            note: 'Options of dropdown. An array of options with id and name',
        },
        placeholder: {
            type: String,
            required: false,
            default: 'Please select an option',
            note: 'Placeholder of dropdown'
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false,
            note: 'Disable the dropdown'
        },
        maxItem: {
            type: Number,
            required: false,
            default: 6,
            note: 'Max items showing'
        },
        initiallySelected: {
            type: Message,
            required: false,
            default: null,
            note: 'initially chosen item'
        }
    },
    data() {
        return {
            selected: null,
            optionsShown: false,
            searchFilter: ""
        }
    },
    created() {
        if (this.initiallySelected != null) {
            this.selectOption(this.options.find(
                (option) => option.id === this.initiallySelected.id)
            )
        }
        this.$emit('selected', this.selected);
    },

    computed: {
        filteredOptions() {
            const filtered = [];
            const regOption = new RegExp(this.searchFilter, 'ig');
            for (const option of this.options) {
                if (this.searchFilter != undefined && (this.searchFilter.length < 1 || option.name.match(regOption))) {
                    if (filtered.length < this.maxItem) filtered.push(option);
                }
            }
            return filtered;
        }
    },
    methods: {
        selectOption(option) {
            this.selected = option;
            this.optionsShown = false;
            this.searchFilter = this.selected.name;
            this.$emit('selected', this.selected);
        },
        showOptions(){
            if (!this.disabled) {
                this.searchFilter = '';
                this.optionsShown = true;
            }
        },
        exit() {
            if (this.selected != null) {
                this.searchFilter = this.selected.name;
                this.$emit('selected', this.selected);
            }
            this.optionsShown = false;
        },
        // Selecting when pressing Enter
        keyMonitor: function(event) {
            if (event.key === "Enter" && this.filteredOptions[0])
            this.selectOption(this.filteredOptions[0]);
        }
    },
    watch: {
        searchFilter() {
            if (this.filteredOptions.length === 0) {
                this.selected = {};
            } else {
                this.selected = this.filteredOptions[0];
            }
            this.$emit('filter', this.searchFilter);
        }
    }
};
</script>
  
  
<style scoped>
.dropdown {
    position: relative;
    display: block;
    margin: auto;
}
.dropdown-input {
background: #fff;
cursor: pointer;
border: 1px solid #e7ecf5;
border-radius: 3px;
color: #333;
display: block;
font-size: .8em;
padding: 6px;
min-width: 30px;
}
.dropdown-input:hover {
    background: #f8f8fa;
}
.dropdown-content {
position: absolute;
background-color: #fff;
min-width: 248px;
max-width: 248px;
max-height: 248px;
border: 1px solid #e7ecf5;
box-shadow: 0px -8px 34px 0px rgba(0,0,0,0.05);
overflow: auto;
z-index: 1;
}
.dropdown-item {
    color: black;
    font-size: .7em;
    line-height: 1em;
    padding: 8px;
    text-decoration: none;
    display: block;
    cursor: pointer;
}
.dropdown-item:hover {
background-color: #e7ecf5;
}
.dropdown:hover .dropdowncontent {
display: block;
}
</style>