
import { defineComponent } from 'vue';
import { useNodeCanvasStore } from '../store';

export default defineComponent({
    name: "ServiceConnectionPromt",
    data() {
        return {
            ncStore: useNodeCanvasStore(),
        }
    },
    methods: {
            exit() {
                this.ncStore.connectingServiceNode = undefined;
            },
            connectAsServer() {
                this.ncStore.connectingServiceNode.service.addServer(this.ncStore.connectingServiceNode.node);
                this.exit();
            },
            connectAsClient() {
                this.ncStore.connectingServiceNode.service.addClient(this.ncStore.connectingServiceNode.node);
                this.exit();
            },
        }
    });
