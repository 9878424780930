// Renderless dialog from https://github.com/danielkellyio/renderless-dialog 

import { reactive } from "vue"
const state = reactive({
    type: 'alert',
    active: false,
    message: '',
    title: '',
    okText: 'Ok',
    cancelText: 'Cancel',
    inputType: 'text',
    html: false,
})

// -----------------------------------
// Private Methods
// -----------------------------------
let close: any // will hold our promise resolve function
const dialogPromise = () => new Promise((resolve: any) => (close = resolve))
const open = (message: string): Promise<any> => {
    state.message = message
    state.active = true
    return dialogPromise()
}

const reset = () => {
    state.active = false
    state.message = ''
    state.okText = 'Ok'
    state.cancelText = 'Cancel'
    state.inputType = 'text'
    state.html = false
    state.title = ''
    state.type = 'alert'
}

// -----------------------------------
// Public interface
// -----------------------------------

const dialog = {
    get state() {
        return state
    },
    title(title: string) {
        state.title = title
        return this
    },
    okText(text: string) {
        state.okText = text
        return this
    },
    
    cancelText(text: string) {
        state.cancelText = text
        return this
    },
    inputType(type: string) {
        state.inputType = type
        return this
    },
    html(enabled: boolean = true) {
        state.html = enabled
        return this
    },
    alert(message: string): Promise<any> {
        state.type = 'alert'
        return open(message)
    },
    confirm(message: string): Promise<any> {
        state.type = 'confirm'
        return open(message)
    },
    prompt(message: string): Promise<any> {
        state.type = 'prompt'
        return open(message)
    },
    cancel(): void {
        close(false)
        reset()
    },
    ok(input: boolean | string = true): void {
        input = state.type === 'prompt' ? input : true
        close(input)
        reset()
    },
}

export default dialog