import { defineStore } from "pinia";
import { Node } from "./components/storeClasses/node";
import { Topic } from "./components/storeClasses/topic";
import { Message, PrimativeTypes } from "./components/storeClasses/message";
import { removeElement } from "../globalModule/components/helperMethods";
import { Service } from "./components/storeClasses/service";
import { v4 } from "uuid";
import Action from "./components/storeClasses/action";
import { Position } from "./components/storeClasses/canvasObjects";


export const useNodeCanvasStore = defineStore({
    id: 'nodeCanvas',
    state: (): State => { return {
        currentId: 10,
        workspaceName: "workspace",
        workspaceId: -1,
        nodes : [],
        topics : [],
        services : [],
        actions: [],
        favouriteElems: [],
        // messages: [...Object.keys(PrimativeTypes).map(k => Message.init({field: k}))],
        nodeInspectorInfo: {}, // {canvasElemId : [{content, comment?}]} // where content is the text content at line index
        nodeTemplates: [
            { name: 'None'},
            { name: "tf_odometry"},
            { name: "go_to"},
            { name: "global_costmap"},
            { name: "amcl"},
        ],
        msgs: [
            new Message(1, 'Bool', 'std_msgs'),
            new Message(2, 'Int8', 'std_msgs'),
            new Message(3, 'Int16', 'std_msgs'),
            new Message(4, 'Int32', 'std_msgs'),
            new Message(5, 'Int64', 'std_msgs'),
            new Message(6, 'UInt8', 'std_msgs'),
            new Message(7, 'UInt16', 'std_msgs'),
            new Message(8, 'UInt32', 'std_msgs'),
            new Message(9, 'UInt64', 'std_msgs'),
            new Message(10, 'Float32', 'std_msgs'),
            new Message(11, 'Float64', 'std_msgs'),
            new Message(12, 'string', 'std_msgs'),
            new Message(13, 'Time', 'std_msgs'),
            new Message(14, 'Duration', 'std_msgs'),

        ],
        editingNodeId: -1, // for editing a node
        editingTopicId: -1, // for editing a topic
        editingService: undefined,
        connectingTopicNode: undefined, // set to object containing node and topic ID for connection prompt
        connectingServiceNode: undefined,
        currDragOffset: {x: 0, y: 0}, // offset for dragging canvas objects
        dragObjId: -1,
        // For saving
        mostRecentSave: undefined, // date time of most recent save
        autosaveTime: 120 // time between autosaves in seconds
    }},
    getters: {},
    actions: {
        getNextId(): number {
            let nextId: number = this.currentId;
            this.currentId += 1;
            return nextId;
        },
        getNode(id: number): Node {
            const node: Node | undefined = this.nodes.find((node: Node) => node.id == id);
            if (!node) {
                throw new StoreError(`Could not find node with id ${id}`);
            }
            return node;
        },
        getTopic(id: number): Topic {
            const topic: Topic | undefined = this.topics.find((topic: Topic) => topic.id == id);
            if (!topic) {
                throw new StoreError(`Could not find topic with id: ${id}`);
            }
            return topic;
        },
        getMsg(id: number): Message {
            const msg: Message | undefined = this.msgs.find((msg: Message) => msg.id == id);
            if (!msg) {
                throw new StoreError(`Could not find message with id: ${id}`);
            }
            return msg;
        },
        /**
         * Moves any object, node or topic, that matches the given id, to the 
         * position {x, y}
         * @param {*} id 
         * @param {*} x 
         * @param {*} y 
         */
        moveObj(id: number, x: number, y: number) {
            [...this.nodes, ...this.topics, ...this.services, ...this.actions]
                .filter((ea: any) => ea.id === id)
                .forEach((ea: any) => ea.position = new Position(x, y));
        },
        /**
         * Creates a new node at position {x, y}, with id equal to the amount of 
         * nodes + 1. Returns the new node
         */
        createNode(x: number, y: number, name: string = "", radius: number=70, color: string = "skyblue") {
            let newNode: Node = new Node(this.getNextId(), x, y, name, radius, color);
            this.nodes.push(newNode);
            return newNode;
        },
        createTopic(x: number, y: number, name: string="") {
            let newTopic = new Topic(this.getNextId(), x, y, name);
            this.topics.push(newTopic);
            return newTopic;
        },
        createService(x: number, y: number, name: string="") {
            const ser = new Service(this.getNextId(), x, y, name);
            this.services.push(ser);
            return ser;
        },
        createAction(x: number, y: number, name="") {
            const action = new Action({position: {x, y}, name, id: this.getNextId()});
            this.actions.push(action);
            return action;
        },
        addCanvasObj(x: number, y: number, obj: any) {
            const addXY = (elem: any) => elem.position = {x, y};
            const addId = (elem: any) => elem.id = this.getNextId();
            let newElem: any;
            if (obj instanceof Node) {
                newElem = Node.fromJSON(JSON.parse(JSON.stringify(obj)));
                newElem.publishers = [];
                newElem.subscribers = [];
                addXY(newElem);
                addId(newElem);
                this.nodes.push(newElem);
            } else if (obj instanceof Topic) {
                // newElem = Topic.fromJSON(JSON.parse(JSON.stringify(obj)));
                // addXY(newElem);
                // addId(newElem);
                // this.topics.push(newElem);
            } else if (obj instanceof Action) {
                // newElem = Action.fromJSON(JSON.parse(JSON.stringify(obj)));
                // addXY(newElem);
                // addId(newElem);
                // // Need to make sure goalservice, resultservice and feedback topics exist ? But also
                // // What need to make clones of goal, result and feedback topics to save as favourite
                // if (this.services.find(ea => ea.id === newElem.goalService) !== undefined) {
                //     const goalSer = Service.fromJSON(JSON.parse(JSON.stringify(newElem.goalService)));
                // }
                // if (this.services.find(ea => ea.id === newElem.resultService) !== undefined) {
                //     const resultSer = Service.fromJSON(JSON.parse(JSON.stringify(newElem.resultService)));
                // }
                // const feedbackTopic = Topic.fromJSON(JSON.parse(JSON.stringify(newElem.feedbackTopic)));

                // this.actions.push(newElem);
            } else if (obj instanceof Service) {
                // newElem = Service.fromJSON(JSON.parse(JSON.stringify(obj)));
                // addXY(newElem);
                // addId(newElem);
                // this.services.push(newElem);
            }
            return newElem;
        },
        removeService(serviceObj: Service) {
            this.services = this.services.filter(ea => ea.id !== serviceObj.id);
        },
        removeAction(actionObj: Action) {
            this.actions = this.actions.filter(ea => ea.id !== actionObj.id);
        },
        removeObj(id: number) {
            let target: any = this.nodes.find((node) => node.id === id);
            let targetArr: Array<any> | undefined = undefined;
            if (target !== undefined) {
                targetArr = this.nodes;
            } else {
                target = this.topics.find((topic) => topic.id === id);
                if (target !== undefined) {
                    targetArr = this.topics;
                    removeTopicFromNodes(this.nodes as Array<Node>, id);
                }
            }
            
            if (targetArr) removeElement<any>(targetArr, target);
        },
        ////////////////////////////////////////////////
        //                Persistence                 //
        ////////////////////////////////////////////////
        /**
         * Saves persistant items in the store to localStorage
         */
        saveStore() {
            // choosing items to keep persistent
            let storeCopy = {
                name: this.workspaceName,
                id: this.workspaceId,
                currentId: this.currentId,
                nodes: this.nodes,
                topics: this.topics,
                nodeTemplates: this.nodeTemplates,
                msgs: this.msgs,
                services: this.services,
                actions: this.actions,
                favouriteElems: this.favouriteElems,
            }
            // saving item
            let workspaces = JSON.parse(localStorage.workspaces).filter((workspace: any) => workspace.id !== this.workspaceId)
            workspaces.unshift(storeCopy)
            localStorage.workspaces = JSON.stringify(workspaces)
            this.mostRecentSave = new Date()
        },
        /**
         * Assumes that workspaceId is an actual workspace ID (will error if not)
         * @param {*} workspaceId 
         */
        loadStore(workspaceId: number) {
            let workspaceJSON = JSON.parse(localStorage.workspaces).find((workspace: any) => workspace.id == workspaceId)
            this.workspaceId = workspaceId
            this.workspaceName = workspaceJSON.name
            this.currentId = workspaceJSON.currentId
            this.nodes = []
            workspaceJSON.nodes.forEach((jsonNode: any) => {
                this.nodes.push(Node.fromJSON(jsonNode))
            })
            this.topics = []
            workspaceJSON.topics.forEach((jsonTopic: any) => {
                this.topics.push(Topic.fromJSON(jsonTopic))
            })
            this.msgs = []
            workspaceJSON.msgs.forEach((jsonMsg: any) => {
                this.msgs.push(Message.fromJSON(jsonMsg));
            })
            this.services = [];
            workspaceJSON.services.forEach((jsonSrv: any) => this.services.push(Service.fromJSON(jsonSrv)));
            this.actions = [];
            workspaceJSON.actions.forEach((jsonAct: any) => {
                this.actions.push(Action.fromJSON(jsonAct));
            });
            this.favouriteElems = [];
            workspaceJSON.favouriteElems.forEach((elem: any) => this.favouriteElems.push(Node.fromJSON(elem)));
            this.nodeTemplates = workspaceJSON.nodeTemplates
        },
        /**
         * Creates a new workspace and loads it into the store
         * @param {*} workspaceName 
         */
        createNewWorkspace(workspaceName: string, workspaceId: number) {
            this.workspaceName = workspaceName;
            this.workspaceId = workspaceId;
            this.nodes = [];
            this.services = [];
            this.topics = [];
            this.actions = [];
            this.favouriteElems = [];
            this.msgs = primitiveMessages;
            this.nodeTemplates = [];
            this.currentId = 1;
            this.editingNodeId = -1;
            this.editingTopicId = -1;
            this.connectingTopicNode = null;
            this.currDragOffset = {x: 0, y: 0};
            this.mostRecentSave = undefined;
            this.autosaveTime = 120;
            this.saveStore();
        },
        /**
         * Called upon loading the nodes canvas, decides whether to create a new
         * workspace or load a workspace from localStorage. If creating a new
         * workspace, also stores that workspace in localStorage.workspaces
         * @param {string} workspaceName
         */
        initStore(workspaceName: string, workspaceId: number) {
            let workspaces = JSON.parse(localStorage.getItem("workspaces") as any);
            if (workspaces.findIndex((workspace: any) => workspace.id == workspaceId) == -1) {
                this.createNewWorkspace(workspaceName, workspaceId);
            } else {
                this.loadStore(workspaceId);
            }
        }
    },
})

const primitiveMessages = [
    new Message(1, 'Bool', 'std_msgs'),
    new Message(2, 'Int8', 'std_msgs'),
    new Message(3, 'Int16', 'std_msgs'),
    new Message(4, 'Int32', 'std_msgs'),
    new Message(5, 'Int64', 'std_msgs'),
    new Message(6, 'UInt8', 'std_msgs'),
    new Message(7, 'UInt16', 'std_msgs'),
    new Message(8, 'UInt32', 'std_msgs'),
    new Message(9, 'UInt64', 'std_msgs'),
    new Message(10, 'Float32', 'std_msgs'),
    new Message(11, 'Float64', 'std_msgs'),
    new Message(12, 'string', 'std_msgs'),
    new Message(13, 'Time', 'std_msgs'),
];
class NodeTemplate {
    name: string;
    constructor(name: string) {
        this.name = name;
    }
}

const defaultNodeTemplates = [
    new NodeTemplate('None'),
    new NodeTemplate("tf_odometry"),
    new NodeTemplate("go_to"),
    new NodeTemplate("global_costmap"),
    new NodeTemplate("amcl"),
];

/**
 * removes a topic from publisher and subscriber lists in each node
 * @param {*} nodes 
 * @param {*} topicId 
 */
function removeTopicFromNodes(nodes: Array<Node>, topicId: number): void {
    nodes.forEach(node => {
        removeElement<number>(node.subscribers, topicId);
        removeElement<number>(node.publishers, topicId);
    })
}


interface State {
    currentId: number;
    workspaceName: string;
    workspaceId: number;
    nodes : Array<Node>;
    topics : Array<Topic>;
    services : Array<Service>;
    actions: Array<Action>;
    favouriteElems: Array<Node | Topic | Service | Action>;
    nodeInspectorInfo: any; // {canvasElemId : [{content, comment?}]} // where content is the text content at line index
    nodeTemplates: Array<NodeTemplate>;
    msgs: Array<Message>
    editingNodeId: number; // for editing a node
    editingTopicId: number; // for editing a topic
    editingService: Service | undefined;
    connectingTopicNode: any; // set to object containing node and topic ID for connection prompt
    connectingServiceNode: any
    currDragOffset: Position // offset for dragging canvas objects
    dragObjId: number
    // For saving
    mostRecentSave: Date | undefined // date time of most recent save
    autosaveTime: number // time between autosaves in seconds
}


class StoreError extends Error {
    constructor(message: string) {
        super(`Store error: ${message}`);
    }
}