import { useNodeCanvasStore } from "../../store";
import { removeNode, removeService } from "../drawCanvas";
import { Position } from "./canvasObjects";

interface Constructor {
    clientsId?: number[],
    serverId?: number,
    position: Position,
    name: string,
    id: number,
    goalServiceId?: number,
    feedbackTopicId?: number,
    resultServiceId?: number
}

interface FromJSON extends Constructor {
    height: number,
    width: number,
    radius: number,
}

export class Action implements Constructor {
    // goalService;
    // resultService;
    // feedbackTopic;
    goalServiceId?: number;
    feedbackTopicId?: number;
    resultServiceId?: number;
    clientsId: number[];
    serverId?: number;
    position: Position;
    name: string;
    id: number;
    height!: number;
    width!: number;
    radius: number = 10;
    constructor({clientsId = [], serverId, position, name = "", id, goalServiceId, feedbackTopicId, resultServiceId}: Constructor) {
        // this.topicId = topicId;
        this.clientsId = clientsId;
        this.serverId = serverId;
        this.position = position;
        this.name = name;
        this.id = id;
        const ncStore = useNodeCanvasStore();
        if (goalServiceId === undefined) {
            this.goalServiceId = ncStore.createService(position.x, position.y, "Goal Service").id;
        } else {
            this.goalServiceId = goalServiceId;
        }
        if (feedbackTopicId === undefined) {
            this.feedbackTopicId = ncStore.createTopic(position.x, position.y, "Feedback Topic").id;
        } else {
            this.feedbackTopicId = feedbackTopicId;
        }
        if (resultServiceId === undefined) {
            this.resultServiceId = ncStore.createService(position.x, position.y, "Result Service").id;
        } else {
            this.resultServiceId = resultServiceId;
        }
        if (resultServiceId === undefined) {
            this.initInnerElems();
        }
    }
    initInnerElems() {
        this.goalService.parent = {id: this.id};
        this.feedbackTopic.parent = {id: this.id};
        this.resultService.parent = {id: this.id};
        this.goalService.height *= 0.8;
        this.resultService.height *= 0.8;
        this.goalService.width *= 1.5;
        this.resultService.width *= 1.5;
        this.width = this.goalService.width + 60;
        this.height = this.goalService.height + this.resultService.height + this.feedbackTopic.height + 60;
        this.setGoalServicePosition();
        this.setFeedbackTopicPosition();
        this.setResultServicePosition();
        this.goalService.radius = 5;
        this.resultService.radius = 5;
        this.feedbackTopic.radius = 5;
    }
    get goalService() {
        const ncStore = useNodeCanvasStore();
        const service = ncStore.services.find(ea => ea.id === this.goalServiceId);
        if (service === undefined) {
            throw new Error("Goal service does not exist");
        }
        return service;
    }
    get resultService() {
        const ncStore = useNodeCanvasStore();
        const service = ncStore.services.find(ea => ea.id === this.resultServiceId);
        if (service === undefined) {
            throw new Error("Result service does not exist");
        }
        return service;
    }
    get feedbackTopic() {
        const ncStore = useNodeCanvasStore();
        const topic = ncStore.topics.find(ea => ea.id === this.feedbackTopicId);
        if (topic === undefined) {
            throw new Error("Feedback topic does not exist");
        }
        return topic;
    }
    setGoalServicePosition() {
        this.goalService.position = {
            x: this.position.x + this.width / 2 - this.goalService.width / 2,
            y: this.position.y + 30,
        };
    }
    setResultServicePosition() {
        this.resultService.position = {
            y: this.goalService.height + this.feedbackTopic.height + this.position.y + 20 + 30,
            x: this.position.x + this.width / 2 - this.resultService.width / 2,
        };
    }
    setFeedbackTopicPosition() {
        this.feedbackTopic.position = {
            y: this.goalService.height + this.position.y + 10 + 30,
            x: this.position.x + this.width / 2 - this.feedbackTopic.width / 2,
        };
    }
    handleDelete(stage: any) {
        removeNode(stage, this.feedbackTopic.id);
        removeService(stage, this.goalService);
        removeService(stage, this.resultService);
        this.goalServiceId = undefined;
        this.resultServiceId = undefined;
        this.feedbackTopicId = undefined;
    }
    fromJSON(args: FromJSON) {
        return Action.fromJSON(args);
    }
    static fromJSON({goalServiceId, feedbackTopicId, resultServiceId, clientsId, serverId, position, name, id, height, width, radius}: FromJSON) {
        const action = new Action({clientsId, serverId, position, name, id, goalServiceId, feedbackTopicId, resultServiceId});
        action.height = height;
        action.width = width;
        action.radius = radius;
        return action;
    }
}

export default Action;
