
import SignInPage from './components/SignInPage.vue'
import SignUpPage from './components/SignUpPage.vue'
import ForgotPasswordPage from "./components/ForgotPasswordPage.vue"
import LoginModule from './LoginModule.vue'

export const loginRoutes = [
    {
        path: '/login',
        name: 'Login',
        component: LoginModule,
        children: [
            {
                path: "",
                name: "Login.SignIn",
                component: SignInPage
            },
            {
                path: "signUp",
                name: "Login.SignUp",
                component: SignUpPage
            },
            {
                path: "resetPassword",
                name: "Login.ResetPassword",
                component: ForgotPasswordPage
            }
        ]
    },
]