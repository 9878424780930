import JSZip from "jszip";
import { useNodeCanvasStore } from "../../store";
import { Service, ServiceParameter } from "../storeClasses/service";
import { Message } from "../storeClasses/message";
import { toCamelCase, toSnakeCase } from "@/modules/globalModule/components/helperMethods";


export function createSrvFiles(zip: JSZip): void {
    const ncStore = useNodeCanvasStore();
    const srvFolder: JSZip = zip.folder("srv")!;
    ncStore.services.forEach((service: Service) => {
        let srvText: string = "";
        service.requestList.forEach((req: ServiceParameter) => {
            const message: Message = ncStore.getMsg(req.type);
            const rosPackage: string = req.rosPackage ? req.rosPackage : toSnakeCase(ncStore.workspaceName);
            const msgPackageText: string = message.rosPackage !== "std_msgs" && rosPackage !== message.rosPackage ? message.rosPackage : "";
            srvText += `${msgPackageText}${message.name.toLowerCase()} ${req.constValue ? req.name.toUpperCase() : req.name}${req.constValue ? `=${req.constValue}` : "" }\n`;
        });
        srvText += "---\n";
        service.responseList.forEach((req: ServiceParameter) => {
            const message: Message = ncStore.getMsg(req.type);
            const rosPackage: string = req.rosPackage ? req.rosPackage : toSnakeCase(ncStore.workspaceName);
            const msgPackageText: string = message.rosPackage !== "std_msgs" && rosPackage !== message.rosPackage ? message.rosPackage : "";
            srvText += `${msgPackageText}${message.name.toLowerCase()} ${req.constValue ? req.name.toUpperCase() : req.name}${req.constValue ? `=${req.constValue}` : "" }\n`;
        });
        srvFolder.file(`${toCamelCase(service.name)}.srv`, srvText);
    });
}