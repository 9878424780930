// Note: might need to have a different 

import { v4 } from "uuid";

/**
 * Class representing a ROS Message <br></br>
 * @param {*} name - name of message
 * @param {*} fields - list of MessageField objects
 */
export const PrimativeTypes = Object.freeze({
    "bool" : false,
    "byte" : 0, // Not sure default value
    "char" : "", // Not sure default value
    "float32" : 0,
    "float64" : 0,
    "int8" : 0,
    "uint8" : 0,
    "int16" : 0,
    "uint16" : 0,
    "int32" : 0,
    "uint32" : 0,
    "int64" : 0,
    "uint64" : 0,
    "string" : "",
});


export class Message {
    id: number;
    name: string;
    rosPackage: string;
    fields: Array<MessageField>;
    constructor(id: number, name: string, rosPackage: string, fields: Array<MessageField> = []) {
        this.id = id;
        this.name = name;
        this.rosPackage = rosPackage;
        this.fields = fields;
    }
    static fromJSON(jsonMsg: any) {
        let fieldArray: Array<MessageField> = []
        jsonMsg.fields.forEach((field: MessageField) => {
            fieldArray.push(MessageField.fromJSON(field))
        })
        return new Message(jsonMsg.id, jsonMsg.name, jsonMsg.rosPackage, fieldArray)
    }
}


export class MessageField {
    msg: number;
    name: string;
    defaultValue: any; // Default values only for primative types, https://design.ros2.org/articles/interface_definition.html, ros 2 does not support default values of complex types.
    isArray: boolean; // Bool if array type
    lteBound: number | undefined; // Positive num, Array size bound, string size bound..., always equal to <= x
    id: string;
    constructor(msg: number, name: string, isArray=false, lteBound: number | undefined =undefined, id: string=v4()) {
        this.msg = msg;
        this.name = name;
        this.isArray = isArray;
        this.lteBound = lteBound;
        this.id = id;
    }
    static fromJSON(jsonField: any) {
        return new MessageField(jsonField.msg, jsonField.name, jsonField.isArray, jsonField.lteBound, jsonField.id);
    }
}