// https://www.geeksforgeeks.org/find-points-at-a-given-distance-on-a-line-of-given-slope/
/**
 * Creates and returns a display object quadratic curve starting from the point 
 * (fromX, fromY), and ending at the point (toX, toY). 
 * The control point of this quadratic curve will be automatically generated
 */
/// <reference path="../../../../node_modules/createjs/builds/1.0.0/createjs.js"/>
/// <reference path="../../../../node_modules/@types/createjs/index.d.ts" />
export function getCurve(fromX: number, fromY: number, toX: number, toY: number, id: number | string) {
    let newLine: any = new createjs.Shape()
    newLine.name = id
    let tangentPoint = getControlPoint(fromX, fromY, toX, toY)
    newLine.graphics.setStrokeStyle(4).beginStroke("#000000").moveTo(fromX, fromY)
    newLine.graphics.quadraticCurveTo(tangentPoint.x, tangentPoint.y, toX, toY)
    return newLine
}
/**
 * 
 * @param {*} fromX 
 * @param {*} fromY 
 * @param {*} toX 
 * @param {*} toY 
 * @param {*} ctrlX 
 * @param {*} ctrlY 
 * @returns 
 */
export function getControlCurve(fromX: number, fromY: number, toX: number, toY: number, ctrlX: number, ctrlY: number, id: number | string) {
    let newLine: any = new createjs.Shape()
    newLine.name = id
    newLine.graphics.setStrokeStyle(2).beginStroke("#000000").moveTo(fromX, fromY)
    newLine.graphics.quadraticCurveTo(ctrlX, ctrlY, toX, toY)
    return newLine
}


/**
 * Creates and returns a display object Arc starting from the point 
 * (fromX, fromY), and ending at the point (toX, toY). 
 */
export function getArc(fromX: number, fromY: number, toX: number, toY: number, id: number | string) {
    let newLine: any = new createjs.Shape()
    newLine.name = id
    let tangentPoint = getControlPoint(fromX, fromY, toX, toY)
    let radius = getRadius(fromX, fromY, toX, toY, tangentPoint.x, tangentPoint.y)
    newLine.graphics.setStrokeStyle(2).beginStroke("#000000").moveTo(fromX, fromY)
    newLine.graphics.arcTo(tangentPoint.x, tangentPoint.y, toX, toY, radius)
    return newLine
}

export function getLine(fromX: number, fromY: number, toX: number, toY: number, id: number | string) {
    let newLine: any = new createjs.Shape()
    newLine.name = id
    newLine.graphics.setStrokeStyle(4)
    newLine.graphics.beginStroke("black")
    newLine.graphics.moveTo(fromX, fromY)
    newLine.graphics.lineTo(toX, toY)
    newLine.graphics.endStroke()
    return newLine
}


/**
 * Given parameters x1, y1, x2, y2
 * calculates the line l between (x1, y1), (x2, y2) with length d,
 * finds midpoint of l, labelled m, and calculates a point p such that the length
 * between p and m is d/2, and such that the line between p and m is 
 * perpendicular to l. p = {x, y}
 * used for generating control point for straight arc
 */
export function getControlPoint(x1: number, y1: number, x2: number, y2: number) {
    // gradient of new line
    let g = (x1 - x2) / (y2 - y1)

    // midpoint of line
    let mx = (x2 + x1) / 2
    let my = (y2 + y1) / 2

    // length of line l
    let d = Math.sqrt(Math.pow(x1 - x2, 2) + Math.pow(y1 - y2, 2))
    
    // return point
    // note: the plus here can be a minus
    let x = mx + (d / 2) * Math.sqrt(1/(1 + Math.pow(g, 2)))
    let y = my + g * (d / 2) * Math.sqrt(1/(1 + Math.pow(g, 2)))

    return { x: x, y: y }
}

/**
 * this function takes in 3 points, p1 (x1, y1), p2 (x2, y2) and p3 (x3, y3).
 * p1 and p2 represent the starting and ending points of the arc respectively,
 * while p3 represents a point that lies on both of the tangents to the arcs at
 * p1 and p2. The function uses p3 to calculate the normal to the tangents at p1
 * and p2, then calculates the interception of those normals, that being the 
 * center of the circle. The function then returns the radius of the circle,
 * using the two point distance formula.
 * @param {*} x1 x coordinate of starting point of arc
 * @param {*} y1 y coordinate of starting point of arc
 * @param {*} x2 x coordinate of ending point of arc
 * @param {*} y2 y coordinate of ending point of arc
 * @param {*} x3 x coordinate of point found by getXY
 * @param {*} y3 y coordinate of point found by getXY
 */
function getRadius(x1: number, y1: number, x2: number, y2: number, x3: number, y3: number) {
    // gradients of normals at p1 and p2 
    let g1 = (x1 - x3) / (y3 - y1)
    let g2 = (x2 - x3) / (y3 - y2)

    // center of circle
    let x = (g1 * x1 - g2 * x2 + y2 - y1) / (g1 - g2)
    let y = g2 * (x - x2) + y2

    // distance between center and point on edge
    return Math.sqrt(Math.pow(x1 - x, 2) + Math.pow(y1 - y, 2))
}

