import ProjectsPage from './views/ProjectsPage.vue';
import ProjectManagerModule from './ProjectManagerModule.vue'
import SettingsPage from "./views/SettingsPage.vue"
import NewWorkspacePage from "./views/NewWorkspacePage.vue"

export const projectManagerRoutes = [
    {
        path: '/projectManager',
        name: 'ProjectManager',
        component: ProjectManagerModule,
        children: [
            {
                path: "",
                name: "ProjectManager.ProjectsPage",
                component: ProjectsPage,
            },
            {
                path: "settings",
                name: "ProjectManager.SettingsPage",
                component: SettingsPage
            },
            {
                path: "newWorkspace",
                name: "ProjectManager.NewWorkspacePage",
                component: NewWorkspacePage
            }
        ]
    },
]