import { rmSpaces, toCamelCase, toSnakeCase } from "@/modules/globalModule/components/helperMethods";
import { useNodeCanvasStore } from "../../store";
import { nodeGenStrategy } from "./nodeGenStrategy";
import { Node } from "../storeClasses/node";
import { Topic } from "../storeClasses/topic";
import { Message } from "../storeClasses/message";
import JSZip from "jszip";
import { Service } from "../storeClasses/service";
/**
 * Code generating strategy for turning nodes into c++ code
 */
export class pyNodeGenStrategy implements nodeGenStrategy { // TODO: Implements nodeGenStrategy interface
    node: Node;
    /**
     * @param {*} node - node that uses this generator strategy 
     */
    constructor(node: Node) {
        this.node = node
    }
    /**
     * TODO: put msgType logic in parent interface when ts migration
     * For use in CMakeLists.txt
     */
    getDependencies(): Array<string> {
        const ncStore = useNodeCanvasStore();
        const dependencies = ["rclpy"];
        this.node.subscribers.forEach((subscriberId: number) => {
            const msgPkg = (ncStore.getMsg((ncStore.getTopic(subscriberId)).msgType)).rosPackage;
            if (!dependencies.includes(msgPkg)) dependencies.push(msgPkg);
        })
        this.node.publishers.forEach(publisherId => {
            const msgPkg = (ncStore.getMsg((ncStore.getTopic(publisherId)).msgType)).rosPackage;
            if (!dependencies.includes(msgPkg)) dependencies.push(msgPkg);
        })
        return dependencies;
    }



    /**
     * returns a string containing the msg imports. there will be only
     * one import per package, and each msg type used within that package
     * will be seperated by commas. Includes all msg types used in publishers
     * and subscribers
     */
    getMsgImports(): string {
        const typeArr: Array<Message> = []; 
        const finishedPackages: Array<string> = []; 
        const ncStore = useNodeCanvasStore();
        // adding subscribers to typeArr
        this.node.subscribers.forEach((subscriberId: number) => { // TODO: also for publishers
            let topic: Topic = ncStore.getTopic(subscriberId);
            let msg: Message = ncStore.getMsg(topic.msgType);
            if (!typeArr.includes(msg)) typeArr.push(msg);
        })
        // adding publishers to typeArr
        this.node.publishers.forEach((publisherId: number) => { // TODO: also for publishers
            let topic = ncStore.getTopic(publisherId);
            let msg = ncStore.getMsg(topic.msgType);
            if (!typeArr.includes(msg)) typeArr.push(msg);
        })
        // formatting import string
        return typeArr.reduce((accumulator: string, msg: Message) => {
            if (!finishedPackages.includes(msg.rosPackage)) {
                accumulator += `from ${msg.rosPackage}.msg import (`;
                typeArr.forEach(subMsg => {
                    if (subMsg.rosPackage === msg.rosPackage) {
                        accumulator += `${subMsg.name}, `;
                    }
                })
                accumulator += `)\n`;
                finishedPackages.push(msg.rosPackage);
            }
            return accumulator;
        }, '');
    }

    /**
     * returns the string containing the publisher creation statements, for 
     * use within the __init__ method of the nodes python file
     */
    getPublisherInitialisations(): string {
        return this.node.publishers.reduce((accumulator: string, publisherId: number) => {
            const ncStore = useNodeCanvasStore();
            const topic = ncStore.getTopic(publisherId);
            const topicMsg = ncStore.getMsg(topic.msgType);
            return accumulator+`        self.${toSnakeCase(topic.name)}_pub = self.create_publisher(${topicMsg.name}, '/${topic.getNameNoFirstSlash()}', 10)\n`;
        }, '');
    }
    /**
     * Returns a string containing the subscriber creation statements, for 
     * use within the __init__ method of the nodes python file. links each subscriber 
     * to a callback function called [subscriber name in snake case]_callback
     */
    getSubscriberInitialisations(): string {
        return this.node.subscribers.reduce((accumulator: string, subscriberId: number) => {
            const ncStore = useNodeCanvasStore();
            const topic = ncStore.getTopic(subscriberId);
            const topicMsg = ncStore.getMsg(topic.msgType);
            return accumulator+`        self.${toSnakeCase(topic.name)}_sub = self.create_subscription(${topicMsg.name}, '/${topic.getNameNoFirstSlash()}', self.${toSnakeCase(topic.name)}_callback, 10)\n`;
        }, '');
    }
    /**
     * Returns a string containing the python definitions for the callback 
     * functions for each subscriber. each callback contains just a pass statement
     */
    getSubscriberCallbacks(): string {
        return this.node.subscribers.reduce((accumulator: string, subscriberId: number) => {
            const ncStore = useNodeCanvasStore();
            const topic = ncStore.getTopic(subscriberId);
            return accumulator+`    def ${toSnakeCase(topic.name)}_callback(self, msg):\n        pass\n    \n`;
        }, '');
    }

    ////////////////////////////////////////////////////////////////////
    //                 Service and Server Functions                   //
    ////////////////////////////////////////////////////////////////////
    getSrvFileImports(): string {
        const ncStore = useNodeCanvasStore();
        let srvImportText = "";
        ncStore.services.filter((srv: Service) => srv.server === this.node.id || srv.clients.includes(this.node.id))
        .forEach((srv: Service) => {
            srvImportText += `from ${toSnakeCase(ncStore.workspaceName)}.srv import ${toCamelCase(srv.name)}\n`
        });
        return srvImportText;
    }


    getServerInitialisations(): string {
        let serverInitText: string = "";
        const ncStore = useNodeCanvasStore();
        ncStore.services.filter((srv: Service) => srv.server === this.node.id).forEach((srv: Service) => {
            serverInitText += `        self.${toSnakeCase(srv.name)}_server = self.create_service(${toCamelCase(srv.name)}, '${toSnakeCase(srv.name)}', self.${toSnakeCase(srv.name)}_callback)\n`;
        });
        return serverInitText;
    }

    getClientInitialisations(): string {
        const ncStore = useNodeCanvasStore();
        let clientInitText: string = "";
        ncStore.services.filter((srv: Service) => srv.clients.includes(this.node.id)).forEach((srv: Service) => {
            clientInitText += `        self.${toSnakeCase(srv.name)}_client = self.create_client(${toCamelCase(srv.name)}, '${toSnakeCase(srv.name)}')
        while not self.${toSnakeCase(srv.name)}_client.wait_for_service(timeout_sec=1.0):
            self.get_logger().info('${toCamelCase(srv.name)} Service not available, waiting again...')\n`;
        })
        return clientInitText;
    }


    getServerCallbacks(): string {
        const ncStore = useNodeCanvasStore();
        let callbacksText: string = "";
        ncStore.services.filter((srv: Service) => srv.server === this.node.id).forEach((srv: Service) => {
            callbacksText += `    def ${toSnakeCase(srv.name)}_callback(self, request, response):
        return response\n`
        });
        return callbacksText;
    }
    ////////////////////////////////////////////////////////////////////
    //                        Code Generation                         //
    ////////////////////////////////////////////////////////////////////
    /**
     * Generates the code file(s) required for the node, and places them in 
     * the correct folders of the zip file
     * @param {*} zip 
     */
    generateCode(zip: JSZip): void {
        let wsFolder = zip.folder(rmSpaces(useNodeCanvasStore().workspaceName))
        if (wsFolder) {
            wsFolder.file(`${toCamelCase(this.node.name)}.py`,`#!/usr/bin/env python3
import rclpy
from rclpy.node import Node

${this.getMsgImports()}${this.getSrvFileImports()}

class ${toCamelCase(this.node.name)}(Node):

    def __init__(self):
        super().__init__('${toSnakeCase(this.node.name)}')
${this.getPublisherInitialisations()}${this.getSubscriberInitialisations()}${this.getServerInitialisations()}${this.getClientInitialisations()}

${this.getSubscriberCallbacks()}${this.getServerCallbacks()}

def main(args=None):
    rclpy.init(args=args)

    ${toSnakeCase(this.node.name)} = ${toCamelCase(this.node.name)}()

    rclpy.spin(${toSnakeCase(this.node.name)})

    ${toSnakeCase(this.node.name)}.destroy_node()
    rclpy.shutdown()


if __name__ == '__main__':
    main()
`);
    }
}}