
import { defineComponent } from 'vue';
import { useProjectManagerStore } from '../store';


export default defineComponent({
    name: "NewWorkspacePage",
    data() {
        return {
            workspaceName: "",
            pmStore: useProjectManagerStore(),
            inputErr: "",
        }
    },
    methods: {
        onExit(): void {
            this.$router.push('/projectManager');
        },
        onSave(): void {
            if (this.workspaceName.trim().length === 0) {
                this.inputErr = "Name cannot be empty";
                return;
            }
            const nextId: number = this.pmStore.getNextId()
            this.$router.push('/nodesCanvas/'
                +this.workspaceName
                +"/"+nextId
            );
        }
    }
})

