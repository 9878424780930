/**
This file includes all the functionality for drawing nodes and topics etc onto
the easelJS canvas

Note that the canvas object is stored in the NodesCanvasPage.vue, hence every
function here will need it to be passed through as a parameter (stage)


DisplayObject naming conventions:
    n - node container
    t - topic container
    l - lines
    nl - line currently being drawn
    nt - node text
    nc - node circle
    tr - topic rectangle
    tt - topic text
    fp - from point (of line)
    tp - to point
    cp - control point
    eb - edit button
    ei - edit image
    db - delete button
    di - delete image


TODO:
    - idea: refactor using classes, nodes + topics, for visualisation, each
    including functions for offsetting stuff
    - use the container class to make moving names + edit buttons a lot simpler
*/
import CloseCirclePNG from "../assets/close-circle.svg";
import ExternalLinkPNG from "../assets/external_link.svg";
import { getArc } from './drawArc';
import { useNodeCanvasStore } from '../store';
import Action from "./storeClasses/action";
import { Node } from "./storeClasses/node";
import { Service } from "./storeClasses/service";
import { Topic } from "./storeClasses/topic";
// import createjs from "createjs";

////////////////////////////////////////////////////////
//                      Lines                         //
////////////////////////////////////////////////////////


/**
 * Removes all current lines and draws new ones
 * @param {*} stage 
 */
export function drawLines(stage: any) {
    const ncStore = useNodeCanvasStore();
    // removing current lines
    stage.children.forEach((child: any) => {
        if (child.name.match(/^(l)\d+$/) != null) {
            stage.removeChild(child);
        }
    });
    // redrawing new ones
    [...ncStore.services, ...ncStore.nodes].forEach(elem => elem.drawArcs(stage, drawLine));
}

function drawLine(stage: any, obj1: any, obj2: any) {
    const name = "l" + obj1.id + obj2.id;
    const arc = getArc(
        obj1.position.x, obj1.position.y, 
        obj2.position.x, obj2.position.y, name
    ) as any;
    arc.id1 = obj1.id;
    arc.id2 = obj2.id;
    stage.addChild(arc);
}

/**
 * removes all new lines, ie lines currently being drawn (with the "nl" tag)
 * @param {*} stage 
 */
function removeNewLines(stage: any) {
    stage.children.forEach((child: any) => {
        if (child.name.match(/^(nl)\d+$/) != null) {
            stage.removeChild(child)
        }
    })
}

////////////////////////////////////////////////////////
//                   Edit Buttons                     //
////////////////////////////////////////////////////////
export function removeService(stage: any, obj: Service) {
    const toRemove = stage.children.find((ea: any) => ea.objId === obj.id);
    stage.removeChild(toRemove);
    useNodeCanvasStore().removeService(obj);
    stage.update();
}
/**
 * 
 * @param {*} stage 
 * @param {*} obj 
 * @param {*} xOffset 
 * @param {*} yOffset 
 */
function addDeleteButton(stage: any, container: any, obj: Topic | Service | Node | Action, xOffset: number, yOffset: number) {
        // adding delete button
        let delImg = new createjs.Bitmap(CloseCirclePNG);
        delImg.name = "di"+obj.id
        delImg.x = xOffset
        delImg.y = yOffset
        let delBtn = new createjs.Shape();
        delBtn.graphics.beginFill("#111").drawCircle(0,0,16) 
        delBtn.x = 16
        delBtn.y = 16
        delBtn.name = "db"+obj.id
        delImg.hitArea = delBtn
        if (obj instanceof Service) {
            delImg.on("click", () => {
                removeService(stage, obj);
            });
        } 
        else if (obj instanceof Action) {
            delImg.on("click", () => {
                obj.handleDelete(stage);
                const toRemove = stage.children.find((ea: any) => ea.objId === obj.id);
                stage.removeChild(toRemove);
                useNodeCanvasStore().removeAction(obj);
                stage.update();
            });
        }
        else {
            delImg.on("click", function() {
                removeNode(stage, obj.id)
            });
        }
        stage.on("editing", function(evt: any) {
            delImg.visible = evt.editing
        })
        delImg.visible = false
        container.addChild(delImg)
        return delImg
}

/**
 * Draws edit buttons onto an object
 * @param {*} stage 
 * @param {*} obj - store object
 * @param {*} xOffset
 * @param {*} yOffset 
 */
function addEditButton(stage: any, container: any, obj: Node | Service | Action | Topic, xOffset: number, yOffset: number, type: string, options={}) {
        // adding edit button
        const editImg: any = new createjs.Bitmap(ExternalLinkPNG);
        editImg.name = "ei"+obj.id;
        editImg.x = xOffset;
        editImg.y = yOffset;
        editImg.scale = 0.6;
        const editBtn = new createjs.Shape();
        editBtn.graphics.beginFill("#000").drawCircle(0,0,20);
        editBtn.x = 13;
        editBtn.y = 17;
        editBtn.name = "eb"+obj.id;
        editImg.on("pressmove", function(evt: any) {
            removeNewLines(stage);
            const arc = getArc(container.x + xOffset, container.y + yOffset, evt.stageX, evt.stageY, "nl"+obj.id);
            stage.addChild(arc);
        })
        editImg.on("pressup", editPressup, null, false, {stage, obj, type, options});
        stage.on("editing", function(evt: any) {
            editImg.visible = evt.editing;
        });
        editImg.visible = false;
        editImg.hitArea = editBtn;
        container.addChild(editImg);
        return editImg;
}

/**
 * Called when the line drawn from the edit button is pressed up
 * @param {*} evt 
 * @param {*} data
 */
function editPressup(evt: any, data: any) {
    removeNewLines(data.stage);
    const objMatch = data.stage.getObjectsUnderPoint(evt.stageX, evt.stageY)[0];
    const other = getElemById(objMatch?.parent.objId);
    data.obj.onArcPressup({ other, options: {objMatch, ...data.options} });
    // data.stage.getObjectsUnderPoint(evt.stageX, evt.stageY)
    // .forEach(objMatch => {
    //     console.log(objMatch);
    //     const other = getElemById(objMatch.parent.objId);
    //     data.obj.onArcPressup({ other, options: objMatch });
    // });
}

////////////////////////////////////////////////////////
//                       Nodes                        //
////////////////////////////////////////////////////////

/**
 * Draws a node onto the stage. 
 * @param {*} stage 
 * @param {*} node - node object as stored in store
 * @param {*} editing - whether or not to display edit buttons initially
 */
function drawNode(stage: any, node: Node, editing: boolean = false) {
    // creating container for node objects
    const container: any = new createjs.Container();
    container.name = "n"+node.id;
    container.x = node.position.x;
    container.y = node.position.y;
    container.objId = node.id;
    
    // creating node graphical circle
    const circle = new createjs.Shape();
    circle.graphics.beginLinearGradientFill(["#5CC8F9", "#0B82C3"], [0, 1], 0, 0, 100, 0).drawCircle(0, 0, node.radius);
    circle.name = "nc"+node.id;
    container.addChild(circle);

    // Adding name to node
    const nameText = new createjs.Text(node.name, "20px Arial", "#000000");
    nameText.x = -nameText.getMeasuredWidth() / 2;
    nameText.y = -node.radius / 2;
    nameText.name = "nt"+node.id;
    container.addChild(nameText);
    
    // movement functionality
    circle.on("mousedown", function(evt: any) {
        let ncStore = useNodeCanvasStore();
        ncStore.currDragOffset = {x: evt.stageX - container.x, y: evt.stageY - container.y};
    });
    circle.on("pressmove", function(evt: any) {
        let ncStore = useNodeCanvasStore()
        ncStore.dragObjId = node.id;
        container.x = evt.stageX - ncStore.currDragOffset.x;
        container.y = evt.stageY - ncStore.currDragOffset.y;
        ncStore.moveObj(node.id, container.x, container.y)
    });
    circle.on("pressup", () => {
        const ncStore = useNodeCanvasStore();
        ncStore.dragObjId = -1;
    });
    // edit node funcitonality
    circle.on("dblclick", function() {
        const ncStore = useNodeCanvasStore();
        ncStore.editingNodeId = node.id;
    });
    
    // functionality once editing node finished
    stage.on("editNodeEnd", function() {
        nameText.text = node.name;
        nameText.x =  -nameText.getMeasuredWidth() / 2; // centering name
    });

    // editing buttons
    const delBtn = addDeleteButton(stage, container, node, 
        node.radius * Math.sin(Math.PI * 1.25) - 16, 
        node.radius * Math.cos(Math.PI * 1.25) - 16
    );
    const editBtn = addEditButton(stage, container, node, 
        node.radius * Math.sin(Math.PI * .75) - 17,
        node.radius * Math.cos(Math.PI * .75) - 17,
        "n"
    );
    editBtn.visible = delBtn.visible = editing;
    circle.shadow = new createjs.Shadow("#7F7F7F", 5, 5, 1);
    stage.addChild(container);
    stage.update();
}

export function removeNode(stage: any, id: number) {
    const ncStore = useNodeCanvasStore();
    ncStore.removeObj(id);
    const toRemove = stage.children.find((ea: any) => ea.objId === id);
    stage.removeChild(toRemove);
    // let childrenCopy = [... stage.children]
    // childrenCopy.forEach(child => {
    //     if (child.name.match(/^(n|t)\d+$/) != null && parseInt(child.name.replace(/\D+/,"")) === id) {
    //         stage.removeChild(child)
    //     }
    // })

}

/**
 * Adds all nodes in store to the canvas
 * @param {*} stage 
 */
export function addNodes(stage: any) {
    let ncStore = useNodeCanvasStore()
    ncStore.nodes.forEach((node) => {
        drawNode(stage, node);
    });
}

/**
 * Creates a new node and draws it onto the stage
 * @param {*} stage 
 * @param {*} x 
 * @param {*} y 
 */
export function addNewNode(stage: any, x: number, y: number) {
    const ncStore = useNodeCanvasStore();
    const newNode = ncStore.createNode(x, y, `Node ${ncStore.nodes.length + 1}`);
    drawNode(stage, newNode, true);
}

////////////////////////////////////////////////////////
//                      Topics                        //
////////////////////////////////////////////////////////


export function drawTopic(stage: any, topic: Topic, editing:boolean=false, options: Options={}) {
    if (options.isAction === undefined && topic.parent?.id !== undefined) {
        return;
    }
    // topic container
    const container: any = new createjs.Container();
    container.x = topic.position.x
    container.y = topic.position.y
    container.name = "t"+topic.id
    container.objId = topic.id;

    // topic rectangle graphic
    const rect = new createjs.Shape()
    rect.graphics.beginLinearGradientFill(["#5CC8F9", "#0B82C3"], [0, 1], 0, 0, 100, 0).drawRoundRect(0, 
        0, topic.width, topic.height, topic.radius
    );
    rect.name = "tr"+topic.id
    container.addChild(rect)
    
    // name text
    const nameText = new createjs.Text(topic.name, "20px Arial", "#000000")
    nameText.x = - (nameText.getMeasuredWidth() - topic.width) / 2
    nameText.y = - (nameText.getMeasuredHeight() - topic.height) / 2
    nameText.name = "tt"+topic.id
    container.addChild(nameText)

    // movement
    rect.on("mousedown", function(evt: any) {
        let ncStore = useNodeCanvasStore()
        ncStore.currDragOffset = {x: evt.stageX - container.x, y: evt.stageY - container.y}
    });
    rect.on("pressup", () => {
        if (options?.pressUp !== undefined) {
            options.pressUp();
            return;
        }
        const ncStore = useNodeCanvasStore();
        ncStore.dragObjId = -1;
    })
    rect.on("pressmove", function(evt: any) {
        if (options?.pressMove !== undefined) {
            options.pressMove(evt);
            return;
        }
        let ncStore = useNodeCanvasStore()
        ncStore.dragObjId = topic.id;
        container.x = evt.stageX - ncStore.currDragOffset.x;
        container.y = evt.stageY - ncStore.currDragOffset.y;
        ncStore.moveObj(topic.id, container.x, container.y)
    });

    // edit topic funcitonality
    rect.on("dblclick", function() {
        let ncStore = useNodeCanvasStore()
        ncStore.editingTopicId = topic.id
    });

    // functionality once editing topic finished
    stage.on("editTopicEnd", function() {
        nameText.text = topic.name
        nameText.x = - (nameText.getMeasuredWidth() - topic.width) / 2
        nameText.y = - (nameText.getMeasuredHeight() - topic.height) / 2
    });
    
    // edit buttons
    if (options.noDelBtn === undefined) {
        const delBtn = addDeleteButton(stage, container, topic, -16, -16);
        delBtn.visible = editing;
    }
    const editBtn = addEditButton(stage, container, topic, topic.width-17, -17, "t");
    editBtn.visible = editing;
    if (options.noShadow === undefined) {
        rect.shadow = new createjs.Shadow("#7F7F7F", 5, 5, 1);
    }
    // stage.addChild(container);
    return container;
}


export function addNewTopic(stage: any, x: number, y: number) {
    const ncStore = useNodeCanvasStore();
    const newTopic = ncStore.createTopic(x, y, `Topic ${ncStore.topics.length + 1}`);
    stage.addChild(drawTopic(stage, newTopic, true));
}


export function initTopics(stage: any) {
    const ncStore = useNodeCanvasStore()
    ncStore.topics.forEach(topic => {
        stage.addChild(drawTopic(stage, topic));
    });
}


////////////////////////////////////////////////////////
//                      Service                       //
////////////////////////////////////////////////////////
//TODO Add new service,
// draw Service
function drawService(stage: any, service: Service, editing:boolean=false, options: Options={}) {
    // Janky for now but if service is owned by an action, i.e. it has a parent id as actions are the only ones which can currently force
    // services to have parent ids then do not draw it unless explicitly told by the parent (action) in options
    if (options.isAction === undefined && service.parent?.id !== undefined) {
        return;
    }
    const container: any = new createjs.Container();
    container.x = service.position.x;
    container.y = service.position.y;
    container.name = "s"+service.id;

    // Draw rectangle
    const rect = new createjs.Shape()
    rect.graphics.beginLinearGradientFill(["#5CC8F9", "#0B82C3"], [0, 1], 0, 0, 100, 0).drawRoundRect(0,
        0, service.width, service.height, service.radius
    );
    // rect.graphics.beginFill(service.color).drawRoundRect(0,
    //     0, service.width, service.height, service.radius
    // );
    rect.name = "sr"+service.id;
    container.addChild(rect);
    
    const nameText = new createjs.Text(service.name, "20px Arial", "#000");
    nameText.x = - (nameText.getMeasuredWidth() - service.width) / 2;
    nameText.y = Math.min(nameText.getMeasuredHeight(), 10);
    nameText.name = "st"+service.id;
    container.addChild(nameText);
    // Set text and contiainer for request
    const addServerContainer: any = new createjs.Container();
    addServerContainer.x = - (service.width - 7 - service.width) / 2;
    addServerContainer.y = service.height / 4 + Math.min(nameText.getMeasuredHeight(), 10);
    container.addChild(addServerContainer);
    const innerFill = new createjs.Shape();
    innerFill.graphics.beginFill("#9C27B0").drawRoundRect(0, 0, service.width - 7, service.height / 5, 16);
    addServerContainer.addChild(innerFill);
    
    const serverContainerText: any = new createjs.Text("Server", "18px Source Sans Pro", "#fff");
    serverContainerText.set({
        textAlign: "center",
        textBaseline: "middle",
    });
    serverContainerText.x = addServerContainer.x + (service.width - 7) / 2;
    serverContainerText.y = addServerContainer.y + (service.height / 5) / 2;
    const serverEditBtn = addEditButton(stage, container, service, -17, addServerContainer.y, "s", {editType: "server"});
    serverContainerText.type = "server";
    addServerContainer.type = "server";

    container.addChild(serverContainerText);

    // Set text and container for response
    const addClientContainer: any = new createjs.Shape();
    addClientContainer.x = - (service.width - 7 - service.width) / 2;
    addClientContainer.y = service.height * 2 / 4 + Math.min(nameText.getMeasuredHeight(), 10);
    addClientContainer.graphics.beginFill("#9C27B0").drawRoundRect(0, 0, service.width - 7, service.height / 5, 16);
    container.addChild(addClientContainer);
    const clientContainerText:any = new createjs.Text("Client", "18px Source Sans Pro", "#fff");
    clientContainerText.set({
        textAlign: "center",
        textBaseline: "middle",
    });
    clientContainerText.x = addClientContainer.x + (service.width - 7) / 2;
    clientContainerText.y = addClientContainer.y + (service.height / 5) / 2;
    const clientEditBtn = addEditButton(stage, container, service, service.width - 17, addClientContainer.y, "s", {editType: "client"});
    addClientContainer.type = "client";
    clientContainerText.type = "client";
    container.addChild(clientContainerText);
    
    // movement
    rect.on("mousedown", function(evt: any) {
        const ncStore = useNodeCanvasStore()
        ncStore.currDragOffset = {x: evt.stageX - container.x, y: evt.stageY - container.y}
    })
    rect.on("pressup", () => {
        if (options?.pressUp !== undefined) {
            options.pressUp();
            return;
        }
        const ncStore = useNodeCanvasStore();
        ncStore.dragObjId = -1;
    })
    rect.on("pressmove", function(evt: any) {
        if (options?.pressMove !== undefined) {
            options.pressMove(evt);
            return;
        }
        const ncStore = useNodeCanvasStore();
        ncStore.dragObjId = service.id;
        container.x = evt.stageX - ncStore.currDragOffset.x;
        container.y = evt.stageY - ncStore.currDragOffset.y;

        ncStore.moveObj(service.id, container.x, container.y);
    });
    rect.on("dblclick", function() {
        const ncStore = useNodeCanvasStore()
        ncStore.editingService = service;
    });
    stage.on("editServiceEnd", () => {
        nameText.text = service.name
        nameText.x = - (nameText.getMeasuredWidth() - service.width) / 2;
        nameText.y = Math.min(nameText.getMeasuredHeight(), 10);
    });
    // editing buttons
    if (options.noDelBtn === undefined) {
        const delBtn = addDeleteButton(stage, container, service, -16, -16);
        delBtn.visible = editing;
    }
    clientEditBtn.visible = serverEditBtn.visible = editing;
    if (options.noShadow === undefined) {
        rect.shadow = new createjs.Shadow("#7F7F7F", 5, 5, 1);
    }

    container.objId = service.id;
    return container;
}

export function addServices(stage: any) {
    const ncStore = useNodeCanvasStore()
    ncStore.services.forEach((service) => {
        stage.addChild(drawService(stage, service));
    });
}
export function addNewService(stage: any, x: number, y: number) {
    const ncStore = useNodeCanvasStore();
    const ser = ncStore.createService(x, y, `Service ${ncStore.services.length + 1}`);
    stage.addChild(drawService(stage, ser, true));
}
////////////////////////////////////////////////////////
//                      Actions                       //
////////////////////////////////////////////////////////
interface Options {
    pressMove?(e: any): void,
    pressUp?(): void,
    noDelBtn?: boolean,
    noShadow?: boolean,
    isAction?: boolean,
}
function drawAction(stage: any, action: Action, editing: boolean = false) {
    const height = action.height;
    const width = action.width;
    const radius = action.radius;
    const container: any = new createjs.Container();
    container.x = action.position.x;
    container.y = action.position.y;
    container.objId = action.id;
    container.name = `a${action.id}`;
    const rect = new createjs.Shape();
    rect.graphics.beginFill("#BDBDBD").drawRoundRect(0,
        0, width, height, radius
    );
    container.addChild(rect);
    const nameText = new createjs.Text(action.name, "15px Arial", "#000");
    nameText.x = - (nameText.getMeasuredWidth() - action.width) / 2;
    nameText.y = Math.min(nameText.getMeasuredHeight(), 3);
    container.addChild(nameText);
    // action.goalService.position.y = action.position.y + 100;
    // action.feedbackTopic.position.y = action.position.y + 230;
    // action.resultService.position.y = action.position.y + 300;
    // action.goalService.height = action.goalService.height * 0.7;
    let goalServiceContainer: any;
    let resultServiceContainer: any;
    let feedbackTopic: any;
    const options = {
        pressMove: (evt: any) => {
            const ncStore = useNodeCanvasStore();
            ncStore.dragObjId = action.id;
            container.x = evt.stageX - ncStore.currDragOffset.x;
            container.y = evt.stageY - ncStore.currDragOffset.y;
            ncStore.moveObj(action.id, container.x, container.y);
            action.setGoalServicePosition();
            action.setResultServicePosition();
            action.setFeedbackTopicPosition();
            goalServiceContainer.x = action.goalService.position.x;
            goalServiceContainer.y = action.goalService.position.y;
            resultServiceContainer.x = action.resultService.position.x;
            resultServiceContainer.y = action.resultService.position.y;
            feedbackTopic.x = action.feedbackTopic.position.x;
            feedbackTopic.y = action.feedbackTopic.position.y;
        },
        pressUp: () => {
            const ncStore = useNodeCanvasStore();
            ncStore.dragObjId = -1;
        },
        noDelBtn: true,
        noShadow: true,
        isAction: true,
    }
    stage.addChild(container);
    goalServiceContainer = drawService(stage, action.goalService, editing, options);
    resultServiceContainer = drawService(stage, action.resultService, editing, options);
    feedbackTopic = drawTopic(stage, action.feedbackTopic, editing, options);
    const delBtn = addDeleteButton(stage, container, action, - 16, - 16);
    delBtn.visible = editing;
    rect.on("mousedown", function(evt: any) {
        const ncStore = useNodeCanvasStore();
        ncStore.currDragOffset = {x: evt.stageX - container.x, y: evt.stageY - container.y};
    });
    rect.on("pressup", () => {
        options.pressUp();
    })
    rect.on("pressmove", function(evt: any) {
        options.pressMove(evt);
    });
    stage.on("actionNameChange", () => {
        nameText.text = action.name
        nameText.x = - (nameText.getMeasuredWidth() - action.width) / 2;
        nameText.y = Math.min(nameText.getMeasuredHeight(), 3);
    });
    stage.addChild(goalServiceContainer, feedbackTopic, resultServiceContainer);
    // stage.setChildIndex(goalServiceContainer, stage.numChildren - 1);

}

export function drawAllActions(stage: any) {
    const ncStore = useNodeCanvasStore()
    ncStore.actions.forEach((action) => {
        drawAction(stage, action);
    });
}

export function addNewAction(stage: any, x: number, y: number) {
    const ncStore = useNodeCanvasStore();
    const action = ncStore.createAction(x, y, `Action ${ncStore.actions.length}`);
    drawAction(stage, action, true);
}

export function addDefinedElem(stage: any, x: number, y: number, obj: Action | Node | Topic | Service) {
    const ncStore = useNodeCanvasStore();
    const elem = ncStore.addCanvasObj(x, y, obj);
    if (elem instanceof Node) {
        drawNode(stage, elem, true);
    }
    // if (elem instanceof Action) {
    //     drawAction(stage, elem, true);
    // } else if (elem instanceof Node) {
    //     drawNode(stage, elem, true);
    // } else if (elem instanceof Topic) {
    //     stage.addChild(drawTopic(stage, elem, true));
    // } else if (elem instanceof Service) {
    //     stage.addChild(drawService(stage, elem, true));
    // }
}


////////////////////////////////////////////////////////
//                      Various                       //
////////////////////////////////////////////////////////

/**
 * Takes the name of a display object, and returns the ID that display object is
 * referencing
 * @param {*} name - name of a display object
 */
export function getId(name: string) {
    return parseInt(name.replace(/\D+/,""))
}

/**
 * Takes the name of a display object and returns the tag, ie the name not 
 * including the id
 * @param {*} name - name of a display object
 */
export function getType(name: string) {
    return name.replace(/\d+/,"")
}

function getElemById(id: number) {
    const store = useNodeCanvasStore();
    const elem = [...store.nodes, ...store.services, ...store.topics, ...store.actions].find(ea => ea.id === id);
    return elem;
}