/**
 * TODO: package.xml and setup.py use maintainer email, maintainer name
 * and package description. These need to be the same across both files.
 * Update this when account details are added to auto generate these according
 * to account
 */

import JSZip from "jszip";
import { useNodeCanvasStore } from "../../store";
import { rmSpaces, toCamelCase, toSnakeCase } from "@/modules/globalModule/components/helperMethods";
import { Node } from "../storeClasses/node";
import { Service } from "../storeClasses/service";



/**
 * Initialises a ROS 2 package, creates the setup.py (if python package),
 * CMakeLists.txt (if c++ package), or ament_cmake_python (if both)
 * as well as the src, launch and include directories.
 * @param {JSZip} zip - empty JSZip to initialise package in
 */
export function initROSPackage(zip: JSZip) {
    let ncStore = useNodeCanvasStore();
    if (ncStore.nodes.every((node) => node.language === 'cpp')) {
        createPackageXml(zip, 2);
        createCMakeLists(zip, false);
        zip.folder('launch');
        let include: JSZip | null = zip.folder('include');
        if (include) include.folder(toSnakeCase(ncStore.workspaceName));
        zip.folder('src');

    } else if (ncStore.nodes.every((node) => node.language === 'py') && ncStore.services.length == 0) {
        createPackageXml(zip, 1);
        createSetupPy(zip);
        createSetupCfg(zip);
        let resource: JSZip | null = zip.folder('resource');
        let wsFolder: JSZip | null = zip.folder(toSnakeCase(ncStore.workspaceName));
        if (resource) resource.file(toSnakeCase(ncStore.workspaceName), '');
        if (wsFolder) wsFolder.file('__init__.py', '');
    } else {
        createPackageXml(zip, 0);
        createCMakeLists(zip, true);
        zip.folder('launch');
        let include: JSZip | null = zip.folder('include');
        let resource: JSZip | null = zip.folder('resource');
        let wsFolder: JSZip | null = zip.folder(toSnakeCase(ncStore.workspaceName));
        if (include) include.folder(toSnakeCase(ncStore.workspaceName));
        if (resource) resource.file(toSnakeCase(ncStore.workspaceName), '');
        if (wsFolder) wsFolder.file('__init__.py', '');
    }
}

/**
 * 
 * @param {JSZip} zip - JSZip for package
 * @param {number} language - number, 1 if python, 2 if c++, 0 if both
 */
function createPackageXml(zip: JSZip, language: number): void {
    let ncStore = useNodeCanvasStore();
    let packageXmlText = `<?xml version="1.0"?>
<?xml-model 
  href="http://download.ros.org/schema/package_format3.xsd" 
  schematypens="http://www.w3.org/2001/XMLSchema"?>
<package format="3">
  <name>${toSnakeCase(ncStore.workspaceName)}</name>
  <version>0.0.0</version>
  <description>TODO: Package description</description>
  <maintainer email="your@email.com">Name</maintainer>
  <license>TODO: License declaration</license>

  <buildtool_depend>rosidl_default_generators</buildtool_depend>
  <exec_depend>rosidl_default_runtime</exec_depend>
  <member_of_group>rosidl_interface_packages</member_of_group>
`;
    if (language == 0) {
    packageXmlText +=`
  <buildtool_depend>ament_cmake</buildtool_depend>
  <buildtool_depend>ament_cmake_python</buildtool_depend>
  
  <depend>rclcpp</depend>
  <depend>rclpy</depend>
  <depend>std_msgs</depend>
  
  <test_depend>ament_lint_auto</test_depend>
  <test_depend>ament_lint_common</test_depend>
  <test_depend>ament_cmake_pytest</test_depend>
  
  <export>
    <build_type>ament_cmake</build_type>
  </export>
</package>`
    } else if (language == 1) {
        packageXmlText += `

  <buildtool_depend>ament_python</buildtool_depend>
  
  <depend>rclpy</depend>

  <test_depend>ament_copyright</test_depend>
  <test_depend>ament_flake8</test_depend>
  <test_depend>ament_pep257</test_depend>
  <test_depend>python3-pytest</test_depend>

  <export>
    <build_type>ament_python</build_type>
  </export>
</package>
`;
    }
    else if (language == 2) {
        packageXmlText += `
  <buildtool_depend>ament_cmake</buildtool_depend>

  <depend>rclcpp</depend>
  <depend>rclpy</depend>
  <depend>std_msgs</depend>

  <test_depend>ament_lint_auto</test_depend>
  <test_depend>ament_lint_common</test_depend>
  

  <export>
    <build_type>ament_cmake</build_type>
  </export>
</package>
`;
    }
    zip.file('package.xml', packageXmlText);
}

////////////////////////////////////////////////////////////////////
//                         Python Package                         //
////////////////////////////////////////////////////////////////////
// NOTE: if setup.py deprecated warning, run "pip install setuptools==58.2.0"

/**
 * Creates a setup.py file in the base folder of the given JSZip folder.
 * only for use if the 
 * @param {JSZip} zip - JSZip for package
 */
function createSetupPy(zip: JSZip): void {
    let ncStore = useNodeCanvasStore();
    let scripts = ncStore.nodes.reduce((accumulator: string, node: Node) =>
        accumulator + `            '${toSnakeCase(node.name)} = ${toSnakeCase(ncStore.workspaceName)}.${toCamelCase(node.name)}:main',\n`
    , '');
    const setupPyText = `
import os
from glob import glob
from setuptools import find_packages, setup

package_name = '${toSnakeCase(ncStore.workspaceName)}'

setup(
    name=package_name,
    version='0.0.0',
    packages=find_packages(exclude=['test']),
    data_files=[
        # Install marker file in the package index
        ('share/ament_index/resource_index/packages', ['resource/' + package_name]),
        # Include our package.xml file
        (os.path.join('share', package_name), ['package.xml']),
        # Include all launch files.
        (os.path.join('share', package_name, 'launch'), glob(os.path.join('launch', '*launch.[pxy][yma]*'))),
    ],
    # This is important as well
    install_requires=['setuptools'],
    zip_safe=True,
    maintainer='Name',
    maintainer_email='your@email.com',
    description='TODO: Package description',
    license='TODO: License declaration',
    tests_require=['pytest'],
    entry_points={
        'console_scripts': [
${scripts}
        ],
    },
)
    `;
    zip.file('setup.py', setupPyText);
}

/**
 * Creates the setup.cfg file, for use with python packages
 * @param {*} zip 
 */
function createSetupCfg(zip: JSZip): void {
    let ncStore = useNodeCanvasStore();
    const setupCfgText = `
[develop]
script_dir=$base/lib/${toSnakeCase(ncStore.workspaceName)}
[install]
install_scripts=$base/lib/${toSnakeCase(ncStore.workspaceName)}
`
    zip.file('setup.cfg', setupCfgText);
}


////////////////////////////////////////////////////////////////////
//                           c++ Package                          //
////////////////////////////////////////////////////////////////////

function createCMakeLists(zip: JSZip, usingPy: boolean): void {
    const ncStore = useNodeCanvasStore();
    
    // for installing cpp nodes
    const cppNodeNames: string = ncStore.nodes.filter(node => node.language === "cpp")
        .reduce((accumulator: string, node: Node) => accumulator + " " + toCamelCase(node.name), "");
    // for installing python nodes
    const pyNodeNames: string = ncStore.nodes.filter(node => node.language === "py")
        .reduce((accumulator: string, node: Node) => accumulator + `${toSnakeCase(ncStore.workspaceName)}/${toCamelCase(node.name)}.py \n`, "");

    // formatted find_package imports
    let findPackagesStr: string = ncStore.nodes.reduce((accumulator: Array<string>, node: Node) => {
        node.codeGenStrategy.getDependencies().forEach((dependency: string) => {
            if (!accumulator.includes(dependency)) accumulator.push(dependency)
        });
        return accumulator;
    }, []).reduce((accumulator, pkg) => accumulator + `find_package(${pkg} REQUIRED)\n`, '');
    // adding services to findPackagesStr

    // string of add_executable calls, each one followed by ament_target_dependencies for that node
    const executablesList: string = ncStore.nodes.reduce((accumulator: string, node: Node) => {
        const dependenciesStr: string = node.codeGenStrategy.getDependencies()
            .reduce((depAccumulator: string, dependency: string) => depAccumulator + " " + dependency, '');
        if (node.language === "cpp") {
            accumulator += `add_executable(${toCamelCase(node.name)} src/${toCamelCase(node.name)}.cpp)\n`;
            accumulator += `ament_target_dependencies(${toCamelCase(node.name)} ${dependenciesStr})\n`;
            if (ncStore.services.find((srv: Service) => 
                srv.clients.includes(node.id) || srv.server === node.id
            ) !== undefined) {
                accumulator += `target_link_libraries(${toCamelCase(node.name)} "\${cpp_typesupport_target}")\n`
            }
            accumulator += '\n';
        }
        return accumulator;
    }, "");

    // compiling services
    let serviceGenStr = '';
    if (ncStore.services.length > 0) serviceGenStr += 'find_package(rosidl_default_generators REQUIRED)\n';
    const srvList: string = ncStore.services.reduce((accumulator: string, srv: Service) => {
        return accumulator + `    "srv/${toCamelCase(srv.name)}.srv"\n`;
    }, '');
    if (srvList.length > 0) serviceGenStr += `rosidl_generate_interfaces(\${PROJECT_NAME}\n${srvList}\n)\n`;
    // checking if any services are connected to cpp nodes
    if (ncStore.services.find((srv: Service)=> {
        if (srv.server != undefined && ncStore.getNode(srv.server).language === "cpp") return true;
        if (srv.clients.find((nodeId: number) => ncStore.getNode(nodeId).language === "cpp") !== undefined) return true;
        return false;
    }) !== undefined) {
        serviceGenStr += `rosidl_get_typesupport_target(cpp_typesupport_target \${PROJECT_NAME} rosidl_typesupport_cpp)`;
    }

    const cMakeListsText: string = `
cmake_minimum_required(VERSION 3.8)
project(${toSnakeCase(ncStore.workspaceName)})

if(CMAKE_COMPILER_IS_GNUCXX OR CMAKE_CXX_COMPILER_ID MATCHES "Clang")
    add_compile_options(-Wall -Wextra -Wpedantic)
endif()

# find dependencies
find_package(ament_cmake REQUIRED)
${usingPy ? 'find_package(ament_cmake_python REQUIRED)\n' : ''}${findPackagesStr}
${serviceGenStr}
if(BUILD_TESTING)
    find_package(ament_lint_auto REQUIRED)
    # the following line skips the linter which checks for copyrights
    # comment the line when a copyright and license is added to all source files
    set(ament_cmake_copyright_FOUND TRUE)
    # the following line skips cpplint (only works in a git repo)
    # comment the line when this package is in a git repo and when
    # a copyright and license is added to all source files
    set(ament_cmake_cpplint_FOUND TRUE)
    ament_lint_auto_find_test_dependencies()
endif()
${usingPy ?
`\nif(BUILD_TESTING)
find_package(ament_cmake_pytest REQUIRED)
set(_pytest_tests
  # Add test files here
)
foreach(_test_path \${_pytest_tests})
  get_filename_component(_test_name \${_test_path} NAME_WE)
  ament_add_pytest_test(\${_test_name} \${_test_path}
    APPEND_ENV PYTHONPATH=\${CMAKE_CURRENT_BINARY_DIR}
    TIMEOUT 60
    WORKING_DIRECTORY \${CMAKE_SOURCE_DIR}
  )
endforeach()
endif()\n` : ''}

include_directories(
  include
  \${catkin_INCLUDE_DIRS}
)

${executablesList}

# Install launch files
install(
  DIRECTORY launch
  DESTINATION share/\${PROJECT_NAME}
)

# Install cpp nodes
install(
  TARGETS ${cppNodeNames}
  DESTINATION lib/\${PROJECT_NAME}
)

${usingPy ? `# Install Python executables\ninstall(PROGRAMS\n  ${pyNodeNames}\n  DESTINATION lib/\${PROJECT_NAME}\n)\n` : ''}
ament_package()
`;
    zip.file('CMakeLists.txt', cMakeListsText);
}
