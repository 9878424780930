
import { defineComponent } from 'vue';
import { useProjectManagerStore } from '../store';
export default defineComponent({
    name: "SavedProject",
    props: {
        projectName: {
            type: String,
            required: true
        },
        id: {
            type: Number,
            required: true
        },
        tableVisible: Boolean,
    },
    data() {
        return {
            pmStore: useProjectManagerStore()
        }
    },
    methods: {
        openSavedProject(): void {
            this.$router.push("/nodesCanvas/"+this.projectName+"/"+this.id)
        }
    },
    emits: ['deleteProject', 'duplicateProject']
})
