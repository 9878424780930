
import { defineComponent } from 'vue';
import { useNodeCanvasStore } from '../store';
import { removeElement } from '@/modules/globalModule/components/helperMethods';
import { ServiceParameter } from './storeClasses/service';
import SearchableDropDown from '@/modules/globalModule/components/SearchableDropDown.vue';
import { faL } from '@fortawesome/free-solid-svg-icons';
import { Message } from './storeClasses/message';

interface State {
    ncStore: any;
    editModeParams: Array<string>; // array of ServiceParamer ids
    constParams: Array<string>;
}

export default defineComponent({
    name: "EditServiceBox",
    data(): State {
        return {
            ncStore: useNodeCanvasStore(),
            editModeParams: [],
            constParams: []
        }
    },
    components: {
        SearchableDropDown
    },
    created() {
        if (this.ncStore.editingService === undefined) {
            this.onExitModal();
        }
    },
    methods: {
        onExitModal() {
            this.$emit("editServiceEnd");
            this.ncStore.editingService = undefined;
            this.editModeParams = [];
        },
        /**
         * @param request true if adding to request list, false if response list
         */
        addNewParam(request: boolean) {
            const newParam: ServiceParameter = new ServiceParameter(this.ncStore.msgs[0].id, "")
            if (request) {
                this.ncStore.editingService.requestList.push(newParam);
            } else {
                this.ncStore.editingService.responseList.push(newParam);
            }
        },
        onEditParam(request: boolean, id: string) {
            if (this.editModeParams.includes(id)) {
                removeElement(this.editModeParams, id);
                if (this.constParams.includes(id)) removeElement(this.constParams, id);
            } else {
                this.editModeParams.push(id)
                if (request && this.ncStore.editingService.requestList.find((param: ServiceParameter) => param.id === id).constValue !== undefined) {
                    this.constParams.push(id);
                } else if (!request && this.ncStore.editingService.responseList.find((param: ServiceParameter) => param.id === id).constValue !== undefined) {
                    this.constParams.push(id);
                }
            }
        },
        onDup(id: string) {
        },
        onDeleteParam(request: boolean, id: string) {
            if (request) {
                this.ncStore.editingService.requestList = this.ncStore.editingService.requestList.filter(
                    (param: ServiceParameter) => param.id !== id
                );
            } else {
                this.ncStore.editingService.responseList = this.ncStore.editingService.responseList.filter(
                    (param: ServiceParameter) => param.id !== id
                );
            }

        },
        chooseParamMsgType(request: boolean, id: string, msg: Message) {
            if (!msg) return;
            if (request) {
                this.ncStore.editingService.requestList.find((reqParam: ServiceParameter) => reqParam.id === id)!.type = msg.id;
            } else {
                this.ncStore.editingService.responseList.find((resParam: ServiceParameter) => resParam.id === id)!.type = msg.id;
            }
        }, 
        onConstBoxClicked(request: boolean, val: Event, id: string) {
            console.log(this.constParams)
            if (request) {
                const param: ServiceParameter = this.ncStore.editingService.requestList.find((reqParam: ServiceParameter) => reqParam.id === id)!
                if (param.constValue === undefined) param.constValue = "";
                else {
                    param.constValue = undefined;
                    if (this.constParams.includes(id)) removeElement(this.constParams, id);
                };
                console.log(param.constValue);
            }
        }

    }
})
