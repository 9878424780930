import NodesCanvasModule from "./NodesCanvasModule.vue"
import NodesCanvasPage from "./views/NodesCanvasPage.vue"

export const nodesCanvasRoutes = [
    {
        path: '/nodesCanvas/:workspaceName/:workspaceId',
        name: 'NodesCanvas',
        component: NodesCanvasModule,
        children: [
            {
                path: "",
                name: "NodesCanvas.NodesPage",
                component: NodesCanvasPage,
            }
        ]
    },
]