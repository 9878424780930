<template>
  <div class="container">
    <img width="104" src="@/modules/nodesCanvasModule/assets/helper_guy.png" alt="image of helper person" />
    <p class="bubble">
      <span>{{ text }}</span>
    </p>
  </div>
</template>

<script>
  export default {
    name: "TextBubble",
    props: {
      text: String,
    }
  }
</script>

<style scoped>
.container {
  display: flex;
}
  p.bubble {
    position: relative;
    min-width: 200px;
    padding-left: 1rem;
    padding-right: 1rem;
    height: 40px;
    background-color: #FFFFFF;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    text-align: center;
    border: none;
    box-shadow: 0px 0px 3px 1px black;
  }
  p.bubble::after {
    box-shadow: -4px 4px 5px -3px black;
    content: ' ';
    position: absolute;
    width: 20px;
    height: 20px;
    left: -6px;
    top: 10px;
    background: white;
    border-left: 1px solid white;
    border-bottom: 1px solid white;
    transform: rotate(45deg);
  }
  p.bubble span {
    position: relative;
    z-index: 1;
  }
</style>