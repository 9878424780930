
import TextBubble from '@/modules/globalModule/components/TextBubble.vue';
import { useNodeCanvasStore } from '../store';
import TextBubbleUp from '@/modules/globalModule/components/TextBubbleUp.vue';
import { PropType, defineComponent } from 'vue';
  export default defineComponent({
    name: "NodesInspectorPage",
    data() {
      return {
        isInfoActive: false,
        textInfoToUse: [] as {comment?: string, content: string}[],
      }
    },
    props: {
      isActive: Boolean,
      textInfo: { // Must have textInfo if no nodeId
        type: Array as PropType<{comment?: string, content: string}[]>, // [{content, comment?}]
        required: false,
      },
      nodeId: { // Must have nodeId if no textInfo
        type: Number,
        required: false,
      },
    },
    created() {
      if (this.textInfo === undefined) {
        const ncStore = useNodeCanvasStore();
        this.textInfoToUse = ncStore.nodeInspectorInfo[this.nodeId!];
      } else {
        this.textInfoToUse = this.textInfo;
      }
    },
    components: {
      TextBubble,
      TextBubbleUp
    }
  });
