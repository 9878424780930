
import { useNodeCanvasStore } from '../store';
import { v4 } from 'uuid';
import { NodeParameter } from './storeClasses/node'
import { defineComponent } from 'vue';

export default defineComponent({
    name: "NodeParams",
    props: {
        isShowParams: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            ncStore: useNodeCanvasStore(),
            editModeParams: [] as string[],
        }
    },
    created() {
        try {
            (this.ncStore.getNode(this.ncStore.editingNodeId))
        }
        catch {
            this.onExitModal();
            return;
        }
    },
    methods: {
        onExitModal() {
            this.editModeParams = [];
            this.$emit('onExit');
        },
        onEditParam(id: string) {
            if (this.editModeParams.includes(id)) {
                this.editModeParams = this.editModeParams.filter(ea => ea !== id);
            } else {
                this.editModeParams = [...this.editModeParams, id];
            }
        },
        onDeleteParam(id: string) {
            this.ncStore.getNode(this.ncStore.editingNodeId)!.removeParameter(id);
        },
        onAddParam() {
            const id = v4();
            this.ncStore.getNode(this.ncStore.editingNodeId)!.addParameter({ name: "", type: "", value: "", id });
            this.onEditParam(id);
        },
        onDup(param: NodeParameter) {
            const id = v4();
            this.ncStore.getNode(this.ncStore.editingNodeId)!.addParameter({ name: param.name, type: param.type, value: param.value, id });
        }
    }
});
