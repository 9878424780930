// Base routes file, contains routes to global modules, as well as imports the 
// routes file from each module

import { createRouter, createWebHistory } from "vue-router";
import BaseRouter from "./components/BaseRouter.vue"
import { loginRoutes } from "./../loginModule/routes"
import { projectManagerRoutes} from "./../projectManagerModule/routes"
import { nodesCanvasRoutes } from "../nodesCanvasModule/routes";

const URL = '' // NOTE: when launching website, change this to global URL

const router = createRouter({
    history: createWebHistory(URL),
    routes: [
        {
            path: "/",
            name: "BaseRouter",
            component: BaseRouter
        },
        ... loginRoutes,
        ... projectManagerRoutes,
        ... nodesCanvasRoutes,
    ]
})

export default router