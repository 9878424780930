import { removeElement } from "@/modules/globalModule/components/helperMethods";
import { Topic } from "./topic";
import { useNodeCanvasStore } from "../../store";
import { Service } from "./service";
import { pyNodeGenStrategy } from "../codeGenerator/pyNodeGenStrategy";
import { cppNodeGenStrategy } from "../codeGenerator/cppNodeGenStrategy";
import { Position } from "./canvasObjects";
import { nodeGenStrategy } from "../codeGenerator/nodeGenStrategy";

export class Node {
    position: Position;
    radius: number;
    languageStr: string = "py";
    codeGenStrat: nodeGenStrategy = new pyNodeGenStrategy(this);
    color: string;
    name: string;
    id: number;
    publishers: Array<number>;
    subscribers: Array<number>;
    parameters: Array<NodeParameter>;
    /**
     * @param {*} id
     * @param {*} x 
     * @param {*} y 
     * @param {*} name 
     * @param {*} radius default - 50
     * @param {*} color - "skyblue"
     * @param {*} language - "py"
     * @param {*} publishers - []
     * @param {*} subscribers - []
     * @param {*} parameters - {name: str, type: str, value: str, id: v4uuid}[]
     */
    constructor(id: number, x: number, y: number, name: string, radius:number=50, color:string="skyblue", language:string="py", 
    publishers: Array<number>=[], subscribers: Array<number>=[], parameters: Array<NodeParameter>=[]
    ) {
        this.id = id;
        this.position = new Position(x, y);
        this.name = name;
        this.radius = radius;
        this.color = color;
        this.language = language; // either "py" or "cpp"
        this.publishers = publishers;
        this.subscribers = subscribers;
        this.parameters = parameters;
    }
    addPublisher(topicId: number) {
        if (this.publishers.indexOf(topicId) === -1) this.publishers.push(topicId)
    }
    addSubscriber(topicId: number) {
        if (this.subscribers.indexOf(topicId) === -1) this.subscribers.push(topicId)
    }
    removePublisher(topicId: number) {
        if (this.publishers.indexOf(topicId) !== -1) removeElement(this.publishers, topicId)
    }
    removeSubscriber(topicId: number) {
        if (this.subscribers.indexOf(topicId) !== -1) removeElement(this.subscribers, topicId)
    }
    addParameter(param: NodeParameter) {
        if (this.parameters.find(ea => ea.id === param.id) !== undefined) {
            return false;
        } else {
            this.parameters.push(param);
            return true;
        }
    }
    removeParameter(id: string) {
        this.parameters = this.parameters.filter(ea => ea.id !== id);
    }
    set language(newLanguage: string) {
        this.languageStr = newLanguage;
        if (newLanguage === "py") {
            this.codeGenStrat = new pyNodeGenStrategy(this);
        } else if (newLanguage === "cpp") {
            this.codeGenStrat = new cppNodeGenStrategy(this);
        }
    }

    get language(): string {
        return this.languageStr;
    }

    get codeGenStrategy(): nodeGenStrategy {
        return this.codeGenStrat;
    }
    onArcPressup({other, options}: any): void {
        if (other instanceof Topic) {
            const connectData = {
                topic: other.id,
                node: this.id
            }
            useNodeCanvasStore().connectingTopicNode = connectData;
        } else if (other instanceof Service) {
            if (options.objMatch?.type === "server") {
                other.addServer(this);
            } else if (options.objMatch?.type === "client") {
                other.addClient(this);
            } else {
                other.onArcPressup({other: this, options});
            }
        }
    }
    drawArcs(stage: any, drawLineFunc: any): void {
        const ncStore = useNodeCanvasStore();
        [...this.subscribers, ...this.publishers].forEach(topicId => drawLineFunc(stage, this, ncStore.getTopic(topicId)));
    }
    fromJSON(args: any) {
        return Node.fromJSON(args);
    }
    /**
     * @param {*} jsonNode - The node as an object converted from a json string
     */
    static fromJSON(jsonNode: any) {
        return new Node(jsonNode.id, jsonNode.position.x, jsonNode.position.y, 
            jsonNode.name, jsonNode.radius, jsonNode.color, jsonNode.language, 
            jsonNode.publishers, jsonNode.subscribers, jsonNode.parameters,
        )
    }

    toJSON() {
        const nodeCopy = {
            position: this.position,
            radius: this.radius,
            language: this.language,
            color: this.color,
            name: this.name,
            id: this.id,
            publishers: this.publishers,
            subscribers: this.subscribers,
            parameters: this.parameters,
        };
        return nodeCopy;
    }
}

export class NodeParameter {
    name: string;
    type: string;
    value: string;
    id: string;
    constructor(id: string, name: string, type: string, value: string) {
        this.name = name;
        this.id = id;
        this.type = type;
        this.value = value;
    }
}