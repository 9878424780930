import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelDynamic as _vModelDynamic, withDirectives as _withDirectives, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-fa71e14c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "dialog-inner" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = { key: 2 }
const _hoisted_6 = ["type"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.dialog.state.active)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (_ctx.dialog.state.title)
            ? (_openBlock(), _createElementBlock("h3", _hoisted_3, _toDisplayString(_ctx.dialog.state.title), 1))
            : _createCommentVNode("", true),
          (_ctx.dialog.state.html)
            ? (_openBlock(), _createElementBlock("p", {
                key: 1,
                innerHTML: _ctx.dialog.state.message
              }, null, 8, _hoisted_4))
            : (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString(_ctx.dialog.state.message), 1)),
          (_ctx.dialog.state.type === 'prompt')
            ? _withDirectives((_openBlock(), _createElementBlock("input", {
                key: 3,
                type: _ctx.dialog.state.inputType,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.userInput) = $event))
              }, null, 8, _hoisted_6)), [
                [_vModelDynamic, _ctx.userInput]
              ])
            : _createCommentVNode("", true),
          _createElementVNode("div", null, [
            (_ctx.dialog.state.type !== 'alert')
              ? (_openBlock(), _createElementBlock("button", {
                  key: 0,
                  class: "rectangle-button button-margin",
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.dialog.cancel()))
                }, _toDisplayString(_ctx.dialog.state.cancelText), 1))
              : _createCommentVNode("", true),
            _createElementVNode("button", {
              class: "rectangle-button button-margin red",
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.dialog.ok(_ctx.userInput)))
            }, _toDisplayString(_ctx.dialog.state.okText), 1)
          ])
        ]),
        _createElementVNode("div", {
          class: "dialog-bg",
          onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.dialog.cancel()))
        })
      ]))
    : _createCommentVNode("", true)
}