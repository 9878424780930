import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0c4e50be"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "dialog-inner" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.ncStore.connectingServiceNode !== undefined)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("p", null, "How would you like to connect the node " + _toDisplayString(_ctx.ncStore.connectingServiceNode.node.name) + " and service " + _toDisplayString(_ctx.ncStore.connectingServiceNode.service.name) + " together?", 1),
          _createElementVNode("button", {
            class: "rectangle-button",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.connectAsServer()))
          }, "Server"),
          _createElementVNode("button", {
            class: "rectangle-button",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.connectAsClient()))
          }, "Client"),
          _createElementVNode("button", {
            class: "rectangle-button exit-btn",
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.exit()))
          }, "Exit")
        ]),
        _createElementVNode("div", {
          class: "dialog-bg",
          onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.exit()))
        })
      ]))
    : _createCommentVNode("", true)
}