import { useNodeCanvasStore } from "../../store";
import { Position } from "./canvasObjects";
import { Node } from "./node";

export class Topic {
    position: Position;
    id: number;
    name: string;
    radius: number;
    width: number;
    height: number;
    color: string;
    msgType: number; // id of msgType Integer
    parent: {id?: number} | undefined;
    constructor(id: number, x: number, y: number, name: string, width: number=150, 
        height: number=50, radius: number=15, color: string="skyblue", msgType: number=1
    ) {
        this.id = id;
        this.position = {x: x, y: y};
        this.name = name;
        this.width = width;
        this.height = height;
        this.radius = radius;
        this.color = color;
        this.msgType = msgType;
    }
    onArcPressup({other}: any) {
        if (other instanceof Node) {
            const connectData = {
                topic: this.id,
                node: other.id
            }
            useNodeCanvasStore().connectingTopicNode = connectData;
        }
    }
    fromJSON(args: any) {
        return Topic.fromJSON(args);
    }
    /**
     * Returns the name of the topic without the leading slash, if one exists
     */
    getNameNoFirstSlash() {
        return  this.name
            .replace(/^~|~/g, function(match, index) { // removing all ~ characters unless they are at the start
            return index === 0 ? match : '';
        }).replace(/^\//, '').replace(/[^a-zA-Z0-9_~{}\/]/g, ''); // removing all invalid characters
    }
    /**
     * @param {*} jsonTopic - The topic as an object converted from a json string
     */
    static fromJSON(jsonTopic: any) {
        const topic = new Topic(jsonTopic.id, jsonTopic.position.x, jsonTopic.position.y, 
            jsonTopic.name, jsonTopic.width, jsonTopic.height, 
            jsonTopic.radius, jsonTopic.color, jsonTopic.msgType,
        );
        topic.parent = jsonTopic.parent;
        return topic;
    }
}