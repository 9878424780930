
import { defineComponent } from 'vue';
import { useNodeCanvasStore } from '../store';
import { Node } from './storeClasses/node';
import { Topic } from './storeClasses/topic';

export default defineComponent({
    name: "AddTopicConnectionPrompt",
    props: {
        active: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            ncStore: useNodeCanvasStore(),
            node: null as unknown as Node | null,
            topic: null as unknown as Topic | null,
            nodeName: "" as String,
            topicName: "" as String
        }
    },
    methods: {
        open() {
            this.topic = this.ncStore.getTopic(this.ncStore.connectingTopicNode?.topic)
            this.node = this.ncStore.getNode(this.ncStore.connectingTopicNode?.node)
            this.nodeName = this.node?.name
            this.topicName = this.topic?.name
        },
        exit() {
            this.ncStore.connectingTopicNode = undefined
        },
        selectSubscriber() {
            this.node?.addSubscriber(this.topic?.id!)
            this.exit()
        },
        selectPublisher() {
            this.node?.addPublisher(this.topic?.id!)
            this.exit()
        },
        selectBoth() {
            this.node?.addSubscriber(this.topic?.id!)
            this.node?.addPublisher(this.topic?.id!)
            this.exit()
        }
    },
    watch: {
        active() {
            if (this.active) this.open()
        }
    }
});

