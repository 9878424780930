
import SavedProject from '../components/SavedProject.vue';
import ProjectTemplate from '../components/ProjectTemplate.vue';
import dialog from '@/modules/globalModule/components/dialog';
import { useProjectManagerStore } from '../store';
import { defineComponent } from 'vue';

export default defineComponent({
    name: "ProjectsPage",
    components: {
        SavedProject,
        ProjectTemplate,
    },
    data() {
        return {
            projectOptionsVisible: false,
            projectSearchText: '',
            projectSearchVisible: false,
            pmStore: useProjectManagerStore()
        }
    },
    mounted: function() {
        this.pmStore.loadWorkspaces()
    },
    methods: {
        editProjects(): void {
            this.projectOptionsVisible = !this.projectOptionsVisible
        },
        settings(): void {
            this.$router.push("/projectManager/settings")
        },
        showProject(projectName: string): boolean {
            return projectName.toLowerCase().includes(this.projectSearchText.toLowerCase())
        },
        searchProjects(): void {
            this.projectSearchText = ''
            this.projectSearchVisible = !this.projectSearchVisible
        },
        async deleteProject(name: string, id: number): Promise<void> {
            let confirmed = await dialog.okText('Yes').cancelText('No').confirm('Are you sure you want to delete the project "'+name+'"?')
            if (confirmed) {
                this.pmStore.removeWorkspace(id)
            }
        },
        createNewProject(): void {
            this.$router.push("projectManager/newWorkspace")
        }
    },

})
