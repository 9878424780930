
import { defineComponent } from 'vue'

export default defineComponent({
    name: "SignUpPage",
    data() {
        return {
            username: '' as String,
            email: '' as String,
            password: '' as String,
            passwordConfirm: '' as String,
            errorVisible: false as Boolean,
            errorText: 'error' as String,
        }
    },
    methods: {
        signUp(username: String, email: String, password: String, passwordConfirm: String) {
            if (!validateEmail(email)) {
                this.errorText = 'Please enter a valid email address!'
                this.errorVisible = true
            } else if (password !== passwordConfirm) {
                this.errorText = 'Passwords do not match!'
                this.errorVisible = true
            } else {
                console.log("signing up with username: "+username+", password: "+password+", email: "+email)
            }
        }
    }
});

function validateEmail(email: String) {
    var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (email.match(validRegex)) return true
    return false
}
