
import { useNodeCanvasStore } from '../store';
import { addDefinedElem, drawLines, addNodes, addNewNode, initTopics, drawAllActions, addNewService, addServices, addNewAction, addNewTopic } from '../components/drawCanvas';
import EditNodeBox from '../components/EditNodeBox.vue';
import AddTopicConnectionPrompt from '../components/AddTopicConnectionPrompt.vue';
import ServiceConnectionPromt from '../components/ServiceConnectionPromt.vue';
import EditTopicBox from '../components/EditTopicBox.vue';
import NodeParams from '../components/NodeParams.vue';
import EditServiceBox from '../components/EditServiceBox.vue';
import AddNewMsgType from '../components/AddNewMsgType.vue';
import EditActionName from '../components/EditActionName.vue';
import CanvasSearch from '../components/CanvasSearch.vue';
import NodeInspector from '../components/NodeInspector.vue';
import { canvasToZip } from '../components/codeGenerator/codeGen';
// import createjs from 'createjs';
import { Service } from '../components/storeClasses/service';
import Action from '../components/storeClasses/action';
import { Node } from '../components/storeClasses/node';
import { Topic } from '../components/storeClasses/topic';
import { defineComponent } from 'vue';
const cjs = createjs;

// interface Data {
//     selectedLine: null,
//     ncStore: any,
//     editing: boolean,
//     mouseInCanvas: boolean,
//     editingNode: boolean,
//     editingTopic: boolean,
//     isShowParams: boolean,
//     isSearch: boolean,
//     stage: any,
// }
export default defineComponent({
    name: "NodesCanvasPage",
    components: {
        EditNodeBox,
        EditTopicBox,
        AddTopicConnectionPrompt,
        NodeParams,
        ServiceConnectionPromt,
        EditServiceBox,
        AddNewMsgType,
        EditActionName,
        CanvasSearch,
        NodeInspector,
    },
    data() {
        return {
            selectedLine: null,
            ncStore: useNodeCanvasStore(),
            editing: false,
            mouseInCanvas: false,
            editingNode: false,
            editingTopic: false,
            isShowParams: false,
            isSearch: false,
            stage: undefined as any,
        }
    },
    created() {
        this.ncStore.initStore(this.$route.params.workspaceName as string, Number(this.$route.params.workspaceId))
        // Create stage for HTML initialisation
        this.stage = new cjs.Stage("node-canvas");
        // automatically calls stage.update()
    },
    mounted() {
        // Recreate stage as HTML now exists
        this.stage = new cjs.Stage("node-canvas");
        addNodes(this.stage);
        initTopics(this.stage);
        addServices(this.stage);
        drawAllActions(this.stage);
        createjs.Ticker.addEventListener("tick", this.onTick);
        this.stage.addEventListener("stagemouseup", this.mouseUp);
        this.stage.mouseMoveOutside = true;
        createjs.Ticker.framerate = 60;
        // size the canvas
        fitToContainer(this.$refs["node-canvas"] as HTMLCanvasElement);
        this.doSaveCanvas()
    },
    methods: {
        onTick() {
            if (this.$route.name !== "NodesCanvas.NodesPage") return;
            fitToContainer(this.$refs["node-canvas"] as HTMLCanvasElement);
            drawLines(this.stage)
            this.editBoxChecks()
            this.stage.update()
        },
        editClicked() {
            this.editing = !this.editing;
            let editEvent = new createjs.Event("editing", false, false);
            editEvent.set({editing: this.editing});
            this.stage.dispatchEvent(editEvent);
        },
        mouseUp(evt: Event) {
            this.ncStore.currDragOffset = {x: 0, y: 0};
        },
        editDragEnd(evt: MouseEvent, type: string) {
            if (!this.withinCanvas(evt.pageX, evt.pageY)) return;

            const bounds = document.querySelector("canvas")?.getBoundingClientRect();
            if (bounds === undefined) {
                return;
            }
            const x = evt.pageX - bounds.left;
            const y = evt.pageY - bounds.top;
            switch (type) {
                case "node":
                    addNewNode(this.stage, x, y);
                    break;
                case "topic":
                    addNewTopic(this.stage, x, y);
                    break;
                case "service":
                    addNewService(this.stage, x, y);
                    break;
                case "action":
                    addNewAction(this.stage, x, y);
                    break;
            }
        },
        editFavDragEnd(evt: MouseEvent, obj: Service | Action | Node | Topic) {
            if (!this.withinCanvas(evt.pageX, evt.pageY)) return;
            if (!(obj instanceof Node)) {
                return;
            }
            const bounds = document.querySelector("canvas")?.getBoundingClientRect();
            if (bounds === undefined) {
                return;
            }
            const x = evt.pageX - bounds.left;
            const y = evt.pageY - bounds.top;
            addDefinedElem(this.stage, x, y, obj);
        },
        onFavMouseUp(evt: Event) {
            if (this.ncStore.dragObjId === -1) {
                return;
            }
            const elem = [...this.ncStore.topics, ...this.ncStore.actions, ...this.ncStore.services, ...this.ncStore.nodes].find(ea => ea.id === this.ncStore.dragObjId);
            if (elem === undefined) {
                return;
            }
            if (!(elem instanceof Node)) {
                return;
            }
            this.ncStore.favouriteElems.push(elem.fromJSON(JSON.parse(JSON.stringify(elem))));
        },
        withinCanvas(x: number, y: number) {
            let bounds = document.querySelector('canvas')?.getBoundingClientRect()
            if (bounds === undefined) {
                return;
            }
            return (x > bounds.left && x < bounds.right && y > bounds.top && y < bounds.bottom)
        },
        // editNode(id: Number) {
        //     this.editingNodeid = id
        // },
        // Checks if either edit node or edit topic boxes are open, performs appropriate
        editBoxChecks() {
            if (this.ncStore.editingNodeId != -1) {
                this.editingNode = true
            } else if (this.editingNode) { // use this.editingNode so only runs once
                this.editingNode = false
                this.stage.dispatchEvent("editNodeEnd")
            }
            if (this.ncStore.editingTopicId != -1) {
                this.editingTopic = true
            } else if (this.editingTopic) { // use this.editingTopic so only runs once
                this.editingTopic = false
                this.stage.dispatchEvent("editTopicEnd")
            }
        },
        toggleIsShowParams(isShow: boolean) {
            this.isShowParams = isShow;
        },
        exitNodesCanvas() {
            this.$router.push('/projectManager')
        },
        /**
         * Automatically saves the canvas if enough time has passed
         */
        doSaveCanvas() {
            if (this.ncStore.mostRecentSave === null || this.ncStore.mostRecentSave === undefined || (this.ncStore.mostRecentSave?.getTime() - (new Date()).getTime()) / 1000 > this.ncStore.autosaveTime
            ) {
                this.ncStore.saveStore()
            }
        },
        generateCode() {
            canvasToZip()
        }
    },
    computed: {
        console: () => console
    }
});

function fitToContainer(canvas: HTMLCanvasElement | null) {
    if (canvas === null) {
        return;
    }
    // Make it visually fill the positioned parent
    canvas.style.width ='100%';
    canvas.style.height='100%';
    // ...then set the internal size to match
    canvas.width  = canvas.offsetWidth;
    canvas.height = canvas.offsetHeight;
}

