import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-99d5f324"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "datalist" }
const _hoisted_2 = ["value"]
const _hoisted_3 = { class: "button-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.isActive)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "outer-modal",
        onClick: _cache[2] || (_cache[2] = (e) => _ctx.onExit(e)),
        ref: "outerModal",
        id: "idOuterModal"
      }, [
        _createElementVNode("div", null, [
          _createElementVNode("datalist", _hoisted_1, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dataListElems, (elems) => {
              return (_openBlock(), _createElementBlock("option", {
                value: elems.id
              }, _toDisplayString(`${(elems as any).parent !== undefined ? _ctx.findParentName((elems as any).parent.id) + '.' : ''}${elems.name}`), 9, _hoisted_2))
            }), 256))
          ]),
          _createElementVNode("div", null, [
            _createElementVNode("div", null, [
              _withDirectives(_createElementVNode("input", {
                class: "input-style",
                placeholder: "Enter id of canvas element",
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.chosen) = $event)),
                list: "datalist"
              }, null, 512), [
                [_vModelText, _ctx.chosen]
              ])
            ]),
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("button", {
                class: "button-style",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onSubmit()))
              }, "Search")
            ])
          ])
        ])
      ], 512))
    : _createCommentVNode("", true)
}