// export interface CanvasObject {
//     position: Position;
// }

// export interface StoreObject {
//     id: number;
// }

// export interface CanvasStoreObject extends CanvasObject, StoreObject {}


export class Position {
    x: number;
    y: number;
    constructor(x: number, y: number) {
        this.x = x;
        this.y = y;
    }
}