
import { defineComponent } from 'vue'

export default defineComponent({
    name: "SettingsPage",
    methods: {
        goToProjectsPage(): void {
            this.$router.push("/projectManager")
        },
        signOut(): void {
            this.$router.push("/login")
        }
    }
})
