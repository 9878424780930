<template>
    <button><fa icon="message"/></button>
    <div v-if="isActive" class="modal">
        <div>
            <div>
                <button @click="onExitModal()" class="icon-button exit-btn"><fa icon="circle-xmark"/></button>
            </div>
            <h2>Message types</h2>
            <div class="main-grid">
                <div class="input-area">
                    <input class="msg-name-style" v-model="msgTypeName" placeholder="Complex typename" />
                    <div class="saved-types-container" v-for="msg in newMsgs">
                        <p>{{ msg.type }}</p>
                        <p>{{ msg.name }}</p>
                        <p v-if="msg.value.trim() !== ''">{{ msg.value }}</p>
                        <div v-else />
                        <button @click="onDelete(msg.id)">Del</button>
                    </div>
                    <div class="new-msg-input">
                        <div>
                            <p>Type</p>
                            <datalist id="options">
                                <option v-for="msg in ncStore.messages">
                                    {{ msg.msgTypeName }}
                                </option>
                            </datalist>
                            <input autocomplete="on" list="options" v-model="currEditingMsg.type" />
                        </div>
                        <div>
                            <p>Name</p>
                            <input v-model="currEditingMsg.name" />
                        </div>
                        <div>
                            <p>Value</p>
                            <input v-model="currEditingMsg.value" />
                        </div>
                        <button @click="onAdd()">Add</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { v4 } from 'uuid';
import { useNodeCanvasStore } from '../store';
import { Message } from './storeClasses/message';

    export default {
        name: "AddNewMsgType",
        data() {
            return {
                topic: useNodeCanvasStore().topics.find(ea => ea.id === useNodeCanvasStore().editingTopicId),
                ncStore: useNodeCanvasStore(),
                newMsgs: [],
                currEditingMsg: {type: "", name: "", value: ""},
                msgTypeName: "",
            };
        },
        props: {
            isActive: Boolean,
        },
        methods: {
            onExitModal() {
                this.$emit("onExitModal");
                console.log(this.ncStore.messages);
            },
            getMsgField() {
                // If not editing a topic, then return empty arr.
                if (this.topic === undefined) {
                    return [];
                }
                return this.topic?.msgType;
            },
            onAdd() {
                if (this.currEditingMsg.type.trim() === "") {
                    alert("Must have a type");
                    return;
                }
                if (!this.ncStore.messages.map(msg => msg.msgTypeName).includes(this.currEditingMsg.type)) {
                    alert("Type must be previously defined");
                    return;
                }
                if (this.currEditingMsg.name.trim() === "") {
                    alert("Must have a name");
                    return;
                }
                this.newMsgs.push({...this.currEditingMsg, id: v4()});
                this.currEditingMsg = {type: "", name: "", value: ""};
            },
            onDelete(id) {
                this.newMsgs = this.newMsgs.filter(ea => ea.id !== id);
            }
        }
    }
</script>

<style scoped>
.saved-types-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    border: 1px solid black;
}
.label-container {
    display: flex;
    justify-content: space-evenly;
}
.new-msg-input {
    display: flex;
    justify-content: space-evenly;
}
.new-msg-input input {
    width: 95%;
}
.new-msg-input p {
    margin: 0;
}
.new-msg-input > div {
    width: 25%;
}
.main-grid {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: repeat(12, 1fr);
}
.msg-name-style {
    grid-column: 4/9;
    grid-row: 1/2;
}
.input-area {
    grid-column: 1/13;
    grid-row: 2/13;
    border: 1px solid black;
    height: 100%;
}
.flex-half-1 {
    display: flex;
    width: 40%;
}
.flex-half-2 {
    display: flex;
}
.exit-btn {
    position: absolute;
    top: -13px;
    left: -15px;
    cursor: pointer;
}
.header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: min(5%, 50px);
}
.modal {
    height: 100vh;
    width: 100vw;
    background: rgba(168, 168, 168, 0.7);
    z-index: 3;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
.modal > div {
    z-index: 5;
    box-shadow: 0px 0px 3px 1px;
    width: 75%;
    background-color: white;
    height: 80%;
    position: relative;
    top: -5%;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 5px;
    /* display: flex; */
    /* flex-direction: column; */
    /* align-items: center; */
    text-align: center;
}
</style>