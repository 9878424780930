import { createApp } from 'vue'
import App from './App.vue'
import { createPinia } from 'pinia'
const pinia = createPinia()

// Font awesome
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
// Global components
import router from './modules/globalModule/routes';
import Dialog from './modules/globalModule/components/Dialog.vue';

const app = createApp(App)
app.use(pinia)
app.use(router)
library.add(fas)
app.component('fa', FontAwesomeIcon)
app.component('Dialog', Dialog)
app.mount('#app')