import JSZip from "jszip";
import FileSaver from "file-saver";
import { useNodeCanvasStore } from "../../store";
import { initROSPackage } from "./initPackage";
import { rmSpaces } from "@/modules/globalModule/components/helperMethods";
import { Node } from "../storeClasses/node";
import { createSrvFiles } from "./srvFileGen";
import { toSnakeCase } from "@/modules/globalModule/components/helperMethods";
/**
 * Converts the canvas to a zip file, and downloads the zip file.
 */
export function canvasToZip() {
    let ncStore = useNodeCanvasStore();
    let zip: JSZip = new JSZip();
    initROSPackage(zip);
    createSrvFiles(zip);
    ncStore.nodes.forEach((node: Node) => node.codeGenStrategy.generateCode(zip));
    zip.generateAsync({ type: 'blob' }).then(function (content) {
        FileSaver.saveAs(content, `${toSnakeCase(ncStore.workspaceName)}.zip`);
    });
}