import { useNodeCanvasStore } from "../../store";
import { Node } from "./node";
import { Position } from "./canvasObjects";
import Action from "./action";
import { Topic } from "./topic";
import { Message } from "./message";
import { v4 } from "uuid";


interface FromJson {
  position: Position,
  id: number, 
  name: string, 
  radius?: number, 
  width?: number, 
  height?: number, 
  color?: string, 
  requestList?: Array<ServiceParameter>,
  responseList?: Array<ServiceParameter>,
  server?: number, 
  clients?: number[],
  parent?: {id?: number}
}
export class Service {
  position: Position;
  id: number;
  name: string;
  radius: number;
  width: number;
  height: number;
  color: string;
  requestList: Array<ServiceParameter> = [];
  responseList: Array<ServiceParameter> = [];
  server: number | undefined; // Node id
  clients: Array<number>; // List of node ids
  parent: {id?: number} | undefined;
  constructor(id: number, x: number, y: number, name: string, width: number=100, height: number=150, 
    radius: number=15, color: string="#2196F3", srvType: number | null=null) {
    this.id = id;
    this.position = {x, y};
    this.name = name;
    this.width = width;
    this.height = height;
    this.radius = radius;
    this.color = color;
    this.clients = [];
  }
  /**
   * 
   * @param {Node} serviceServer 
   */
  addServer(serviceServer: Node): void {
    this.server = serviceServer.id;
  }
  removeServer(): void {
    this.server = undefined;
  }
  /**
   * 
   * @param {Node} serviceClient 
   * @returns 
   */
  addClient(serviceClient: Node) {
    if (this.clients.find(ea => ea === serviceClient.id) !== undefined) {
      return false;
    } else {
      this.clients.push(serviceClient.id);
    }
  }
  removeClient(client: Node) {
    this.clients = this.clients.filter(id => id !== client.id);
  }
  onArcPressup({other, options}: {other: Node | Service | Topic | Action, options: any}) {
    if (this.clients.find(clientId => clientId === other?.id) !== undefined && this.server === other.id) {
      return;
    }
    if (other instanceof Node) {
      if (options.editType === "server") {
        this.addServer(other);
      } else if (options.editType === "client") {
        this.addClient(other);
      } else {
        useNodeCanvasStore().connectingServiceNode = {node: other, service: this};
      }
    }
  }
  drawArcs(stage: any, drawLineFunc: (stage: any, obj1: any, obj2: any) => any) {
    const serverObj = this.serverObj();
    if (serverObj !== undefined) {
      drawLineFunc(stage, this, serverObj);
    } else {
      this.server = undefined;
    }
    this.clientObjs().forEach(client => drawLineFunc(stage, this, client));
  }
  clientObjs(): Node[] {
    const ncStore = useNodeCanvasStore();
    return ncStore.nodes.filter(node => this.clients.includes(node.id));
  }
  serverObj() {
    const ncStore = useNodeCanvasStore();
    return ncStore.nodes.find(ea => ea.id === this.server);
  }
  fromJSON(args: FromJson) {
    return Service.fromJSON(args);
  }
  static fromJSON({position, id, name, radius, width, height, color, requestList, responseList, server, clients, parent}: FromJson) {
    const ser = new Service(id, position.x, position.y, name);
    const t = (value: unknown): value is {} | null => value !== undefined;
    if (t(radius)) {
      ser.radius = radius;
    }
    if (t(width)) {
      ser.width = width;
    }
    if (t(height)) {
      ser.height = height;
    }
    if (t(color)) {
      ser.color = color;
    }
    if (t(server)) {
      ser.server = server;
    }
    if (t(clients)) {
      ser.clients = clients;
    }
    // TODO: check that this populates correctly
    if (t(requestList)) {
      ser.requestList = requestList;
    }
    if (t(responseList)) {
      ser.responseList = responseList; 
    }
    ser.parent = parent;
    return ser;
  }
}

export class ServiceParameter {
  id: string
  type: number; // number representing message id
  name: string; // name of parameters
  constValue: string | undefined; // value if parameter is constant, undefined if not constnat
  rosPackage: string | undefined; // undefined if this package
  constructor(type: number, name: string, constValue: string | undefined = undefined, rosPackage: string | undefined = undefined, id: string = v4()) {
      this.type = type;
      this.name = name;
      this.id = id;
      this.constValue = constValue;
      this.rosPackage = rosPackage;
  }
}