
import { defineComponent } from 'vue'

export default defineComponent({
    name: "ForgotPasswordPage",
    data() {
        return {
            email: '' as String,
        }
    },
    methods: {
        resetPassword(email: String) {
            console.log("Resetting password for email: "+email)
        }
    }
});
