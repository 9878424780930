
import dialog from '@/modules/globalModule/components/dialog'
import { defineComponent } from 'vue';
export default defineComponent({
    name: "Dialog",
    data() {
        return {
            userInput: '',
            dialog
        }
    },
})
