import { defineStore } from "pinia";

interface State {
    workspaces: Array<any>;
    nextWorkspaceId: number;
    projectTemplates: Array<ProjectTemplate>
}

interface ProjectTemplate {
    name: string;
    shortName: string;
}

export const useProjectManagerStore = defineStore({
    id: 'projectManager',
    state: (): State => { return {
            workspaces: [],
            nextWorkspaceId: 1,
            projectTemplates: [
                // {name: 'template 1', shortName: 'T1'},
                // {name: 'template 2', shortName: 'T2'},
                // {name: 'template 3', shortName: 'T3'},
                // {name: 'template 4', shortName: 'T4'},
                // {name: 'template 5', shortName: 'T5'},
                // {name: 'template 6', shortName: 'T6'},
            ]
        }
    },
    // getter: {},
    actions: {
        loadWorkspaces(): void {
            if (localStorage.getItem("nextWorkspaceId") !== null) {
                this.nextWorkspaceId = Number(localStorage.nextWorkspaceId)
            } else this.nextWorkspaceId = 1
            
            const workspaces: string | null = localStorage.getItem("workspaces")
            if (workspaces === null) {
                this.workspaces = []
            } else {
                this.workspaces = JSON.parse(workspaces)
            }
            this.saveWorkspaces()
        },
        saveWorkspaces(): void {
            localStorage.workspaces = JSON.stringify(this.workspaces)
            localStorage.nextWorkspaceId = this.nextWorkspaceId
        },
        removeWorkspace(id: number): void {
            this.workspaces = this.workspaces.filter(item => item.id != id)
            this.saveWorkspaces()
        },
        duplicateWorkspace(id: number): void {
            let workspaceToDuplicate = {...this.workspaces.find(workspace => workspace.id === id)}
            workspaceToDuplicate.id = this.getNextId()
            this.workspaces.push(workspaceToDuplicate)
            this.saveWorkspaces()
        },
        getNextId(): number {
            this.nextWorkspaceId += 1;
            this.saveWorkspaces()
            return this.nextWorkspaceId - 1;
        }
    }
})