
import { defineComponent } from 'vue';
import { useNodeCanvasStore } from '../store';
import NodeInspector from './NodeInspector.vue';
import { Node } from './storeClasses/node';
interface Data {
    languagePython: boolean,
    ncStore: ReturnType<typeof useNodeCanvasStore>,
    nodeToEdit: Node,
    name: string,
    isInspectActive: boolean,
}
export default defineComponent({
    name: "EditNodeBox",
    props: {
        active: {
            type: Boolean,
            default: false
        }
    },
    data(): Data {
        return {
            languagePython: true,
            ncStore: useNodeCanvasStore(),
            nodeToEdit: null as unknown as Node,
            name: "",
            isInspectActive: false,
        }
    },
    methods: {
        open() {
            const editNode = this.ncStore.nodes.find((node) => node.id === this.ncStore.editingNodeId)
            if (editNode === undefined) {
                this.exit();
                return;
            }
            this.nodeToEdit = editNode;
            this.name = this.nodeToEdit.name
            this.languagePython = this.nodeToEdit.language === "py"
        },
        exit() {
            this.ncStore.editingNodeId = -1
        },
        setLanguage(languagePython: boolean) {
            this.languagePython = languagePython
        },
        saveAndExit() {
            this.nodeToEdit.name = this.name
            this.nodeToEdit.language = this.languagePython ? "py" : "cpp"
            this.exit()
        },

    },
    watch: {
        active() {
            if (this.active) this.open()
        }
    },
    components: {
        NodeInspector,
    }
});

