
import { defineComponent } from 'vue';

export default defineComponent({
    name: "ProjectTemplate",
    props: {
        projectName: String,
        shortName: String
    }
})
