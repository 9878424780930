<template lang="">
    <div>
        <router-view></router-view>
    </div>
</template>
<script>

export default {
    name: "NodesCanvasModule"
}
</script>
<style lang="">
    
</style>